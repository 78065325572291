import CustomerDetail from "@/components/CustomerDetail";
import VehicleQR from "@/components/VehicleQR";
import { calculatePercentageDifference } from "@/utils/calculatePercentageDifference";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { BikeIcon, KeyIcon } from "@/assets/Icons";

interface IProps {
  store: any;
  productDetail: IVehicleInfo;
  orderDetails: any;
  storeOrderDetails: any;
  openQRModal: boolean;
  setOpenQRModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownload?: () => void;
}

const DmxVehicleInfo = ({
  store,
  productDetail,
  orderDetails,
  storeOrderDetails,
  openQRModal,
  setOpenQRModal,
  handleDownload,
}: IProps) => {
  const { isDesktop } = useScreenDetector();

  return (
    <div
      className={cn("flex flex-col px-4 mt-[-30px]", isDesktop && "px-0 py-2")}
    >
      <div className="border-t w-full"></div>
      <div className="flex gap-10 mt-5">
        {store?.partner_type === "fofo" && (
          <div className="flex flex-col gap-2 ">
            <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
              <KeyIcon
                size={16}
                oneStopColor="#606060"
                twoStopColor="#606060"
              />{" "}
              <span className="ml-1">Purchase Price</span>
            </div>
            <p className="text-2xl font-medium">
              ₹{productDetail?.purchase_price?.displayValue}
            </p>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
            <BikeIcon size={16} oneStopColor="#606060" twoStopColor="#606060" />
            <span className="ml-1">
              {productDetail.status === "available"
                ? "Website Price"
                : "Selling Price"}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <p className="text-2xl font-medium">
              ₹{productDetail?.price?.displayValue}
            </p>{" "}
            {store?.partner_type === "fofo" && (
              <div className="bg-green200 text-xs text-[#5FA74E] rounded p-1 w-fit h-fit">
                +
                {calculatePercentageDifference(
                  productDetail?.purchase_price?.value,
                  productDetail?.price?.value,
                )}
                %
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="py-2">
        {productDetail.status !== "available" && (
          <CustomerDetail
            orderDetails={storeOrderDetails ?? orderDetails}
            productDetail={productDetail}
            handleDownload={handleDownload}
          />
        )}

        {productDetail.status === "available" && (
          <VehicleQR
            openQRModal={openQRModal}
            productDetail={productDetail}
            setOpenQRModal={setOpenQRModal}
          />
        )}
      </div>
    </div>
  );
};

export default DmxVehicleInfo;
