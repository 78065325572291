import { differenceData, trackImageViewerOpened } from "./data.tsx";
import { Dialog } from "@/components/ui/dialog";
import CarouselImagePreview from "./CarouselImagePreview";
import { useEffect, useState } from "react";
import ProductInfo from "./ProductInfo";
import DriveDifference from "./DriveDifference";
import CarouselSection from "./CarouselSection";
import TestRide from "./TestRide";
import { useProductDetailQuery } from "@/store/services/product";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  checkUpcomingTestRide,
  checkWhatsappTourExists,
  getWishListedVehicle,
} from "@/store/services/scheduleApi";
import { useDispatch, useSelector } from "react-redux";
import TopNavigation from "./TopNavigation";
import { CAROUSEL_IMAGE_UPDATE_INTERVAL } from "@/utils/constants";
import Navbar from "@/components/Navbar/index.tsx";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import {
  addOrRemoveWishlist,
  setIsWishListed,
} from "@/store/features/wishListSlice.tsx";
import ProductDetailSkeletonLoader from "@/components/SkeletonLoaders/ProductDetail/index.tsx";
import { generateMetaInfo } from "../../../hyperion/seoData.js";
import SEO from "@/components/SEO/index.tsx";
import ImageViewer from "./ImageViewer.tsx";
import mixpanel from "mixpanel-browser";
import { useToast } from "@/components/ui/use-toast.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import { meta_conversion_api } from "@/utils/metaPixels.ts";
import ErrorPage from "../Home/Error.tsx";
import { usePendingAction } from "@/hooks/usePendingAction.tsx";
import { cn } from "@/lib/utils.ts";
import SoldOut from "./SoldOut.tsx";
import { getReturningCustomerData } from "@/utils/returningCustomer.ts";
import { getCurrentDateAndTime } from "@/utils/date.ts";
import Image from "@/components/ui/image.tsx";
import { useDataContext } from "@/context/useDataContext.tsx";

const ProductDetail = () => {
  const { data: ssrData } = useDataContext();
  const { isDesktop, isMobile } = useScreenDetector();
  const [openCarouselImgPreview, setOpenCarouselImgPreview] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [whatsappTourExists, setWhatsappTourExists] = useState(null);
  const [isCarouselAutoPlay, setIsCarouselAutoPlay] = useState(true);
  const [testRideDetails, setTestRideDetails] = useState<{
    date: string;
    exists: boolean;
  } | null>(null);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { handleLocationSelection, city } = useCurrentCity();
  const { handlePendingAction } = usePendingAction();
  const vehicleId = params.vehicleId;
  const user = useSelector((state: any) => state.user.currentUser);
  const { wishList } = useSelector((state: any) => state.wishList);
  const { data: product = {}, isError } = useProductDetailQuery({
    vehicleId,
  });
  const productDetail = (product.data as IVehicleInfo) || ssrData;
  const metaData = generateMetaInfo({
    path: location.pathname,
    city,
    productDetail,
  });
  const isLoading = !productDetail && !isError;
  const isVehicleAvailable =
    productDetail?.availabilityStatus !== "unavailable";

  const fetchUpcomingWhatsappTour = async () => {
    const data = await checkWhatsappTourExists({
      user_id: user?.id,
      vehicle_id: productDetail?.vehicleId,
    });

    if (data.status === "OK") {
      setWhatsappTourExists(data.data);
    }
  };

  useEffect(() => {
    if (!productDetail) return;

    const returningCustomerData =
      JSON.parse(localStorage.getItem("returningCustomerData") as string) || [];

    if (
      returningCustomerData?.data?.some(
        (item: any) =>
          item?.data?.vehicleId === productDetail?.vehicleId &&
          (item?.type === "test-ride" || item?.type === "whatsapp-tour"),
      )
    ) {
      return;
    }

    getReturningCustomerData({
      vehicleDetail: {
        type: "pdp-viewed",
        date_time: getCurrentDateAndTime(), // sends the current date and time
        version: "v1.0-pdp-viewed",
        data: { ...productDetail },
      },
    });
  }, [productDetail]);

  useEffect(() => {
    if (productDetail) {
      mixpanel.track_pageview({
        vehicle_brand: productDetail?.brand,
        vehicle_model: productDetail?.modelName,
        store_location: productDetail?.storeName,
        vehicle_id: productDetail?.vehicleId,
        city_name: productDetail?.city,
        page: "product_detail_page_viewed",
      });
    }

    if (!user || !productDetail) return;

    const fetchUpcomingTestRideDetails = async () => {
      const data = await checkUpcomingTestRide({
        user_id: user?.id,
        vehicle_id: productDetail?.vehicleId,
      });

      if (data.status === "OK") {
        setTestRideDetails(data.data);
      }
    };

    if (!testRideDetails) fetchUpcomingTestRideDetails();
    if (!whatsappTourExists) fetchUpcomingWhatsappTour();
  }, [user?.id, productDetail?.vehicleId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isCarouselAutoPlay) {
        clearInterval(intervalId);
        return;
      }

      if (currentImageIndex + 1 >= productDetail?.imagePaths?.length) {
        setIsCarouselAutoPlay(false);
        setCurrentImageIndex(0);
        return;
      }

      const nextIndex =
        (currentImageIndex + 1) % productDetail?.imagePaths?.length;

      setCurrentImageIndex(nextIndex);
    }, CAROUSEL_IMAGE_UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, productDetail?.imagePaths?.length]);

  useEffect(() => {
    if (!user && wishList?.length <= 0) return;
    const wishListedVehicleAvailable = async () => {
      const fetchWishList = await getWishListedVehicle(`compact=true`);
      if (!fetchWishList) {
        dispatch(setIsWishListed([]));
      } else {
        dispatch(
          setIsWishListed([...new Set([...fetchWishList, ...wishList])]),
        );
      }
    };

    wishListedVehicleAvailable();
  }, [user]);

  // This effect runs once on component mount to determine and set the current city based on the URL path.
  useEffect(() => {
    handleLocationSelection({
      pathname: location.pathname,
    });

    meta_conversion_api({
      eventName: "ViewContent",
      eventId: vehicleId,
      phoneNumber: user?.phoneNumber,
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "ViewContent");
  }, []);

  const handleWishListClick = async () => {
    if (!user) {
      handlePendingAction({
        actionType: "UPDATE_WISHLIST",
        payload: [
          wishList,
          productDetail,
          location.pathname,
          toast,
          city,
          user?.phoneNumber,
          dispatch,
        ],
      });
      navigate(`${location.pathname}?login=true`);
      return;
    }

    await addOrRemoveWishlist(
      wishList,
      productDetail,
      location.pathname,
      toast,
      city,
      user?.phoneNumber,
      dispatch,
    );
  };

  if (isError) {
    return <ErrorPage onFallback={false} />;
  }

  return (
    <>
      <SEO metaData={metaData} />
      <section
        className={cn(
          "flex flex-col relative pb-[20px]",
          !isVehicleAvailable && "pb-[86px]",
        )}
      >
        {!isMobile && (
          <Navbar disableLocation={true} backgroundColor="bg-white" />
        )}
        <div
          className={
            "max-w-7xl w-full mx-auto md:mt-32 lg:mt-20 mb-10 md:mb-24"
          }
        >
          <Dialog open={openCarouselImgPreview}>
            <CarouselImagePreview
              isVehicleAvailable={isVehicleAvailable}
              productDetail={productDetail}
              setOpenCarouselImgPreview={setOpenCarouselImgPreview}
              handleWishListClick={handleWishListClick}
              wishList={wishList}
              testRideDetails={testRideDetails}
              whatsappTourExists={whatsappTourExists}
              fetchUpcomingWhatsappTour={fetchUpcomingWhatsappTour}
            />
          </Dialog>

          {isLoading && <ProductDetailSkeletonLoader />}

          {productDetail && (
            <div className="flex flex-col lg:flex-row gap-4 relative">
              <div className="flex flex-col justify-end md:flex-row-reverse md:h-[400px]">
                <span className="w-full relative md:w-[628px]">
                  <TopNavigation
                    isVehicleAvailable={isVehicleAvailable}
                    productDetail={productDetail}
                    handleWishListClick={handleWishListClick}
                    wishList={wishList}
                  />
                  {!isVehicleAvailable && (
                    <div className="bg-white absolute bottom-5 py-1 px-6 rounded-e-md z-10">
                      <p className="text-sm">Sold Out</p>
                    </div>
                  )}
                  <Image
                    transform="q-10"
                    src={productDetail.imagePaths?.[currentImageIndex]}
                    alt={productDetail.vehicleName}
                    className={cn(
                      "w-full h-80 md:h-[400px] md:w-[628px] md:rounded-md ease-linear object-cover",
                      !isVehicleAvailable && "grayscale",
                    )}
                    onClick={() => {
                      setOpenImageViewer(true);
                      trackImageViewerOpened({
                        productDetail,
                        currentImageIndex,
                      });
                    }}
                  />
                  {openImageViewer && (
                    <Dialog open={openImageViewer}>
                      <ImageViewer
                        productDetail={productDetail}
                        imagePaths={productDetail.imagePaths}
                        setOpenImageViewer={setOpenImageViewer}
                        activeImage={currentImageIndex}
                      />
                    </Dialog>
                  )}
                </span>

                <CarouselSection
                  isVehicleAvailable={isVehicleAvailable}
                  imagePaths={productDetail.imagePaths}
                  setOpenCarouselImgPreview={setOpenCarouselImgPreview}
                  productDetail={productDetail}
                />
              </div>

              <ProductInfo
                isVehicleAvailable={isVehicleAvailable}
                productInfo={productDetail}
                testRideDetails={testRideDetails}
                user={user}
                showPrice={true}
                showEmi={true}
                whatsappTourExists={whatsappTourExists}
                fetchUpcomingWhatsappTour={fetchUpcomingWhatsappTour}
              />
            </div>
          )}

          <DriveDifference
            differenceData={differenceData}
            isVehicleAssured={productDetail?.drivexAssured}
          />

          {!isDesktop && (
            <div className="bg-white border-t fixed bottom-0 right-0 left-0 w-full py-2 px-3">
              {isVehicleAvailable && (
                <TestRide
                  testRideDetails={testRideDetails}
                  vehicleDetails={productDetail}
                  whatsappTourExists={whatsappTourExists}
                  fetchUpcomingWhatsappTour={fetchUpcomingWhatsappTour}
                />
              )}

              {!isVehicleAvailable && <SoldOut />}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default ProductDetail;
