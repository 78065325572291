import Image from "@/components/ui/image.tsx";
import { Dialog } from "@/components/ui/dialog";
import ImageViewer from "@/pages/ProductDetail/ImageViewer";
import CarouselSection from "@/pages/ProductDetail/CarouselSection";
import ProductInfo from "@/pages/ProductDetail/ProductInfo";
import { cn } from "@/lib/utils";
import { trackImageViewerOpened } from "@/pages/ProductDetail/data";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CAROUSEL_IMAGE_UPDATE_INTERVAL,
  DISCOUNT_MAX_VALUE,
} from "@/utils/constants";
import TopNavigation from "@/pages/ProductDetail/TopNavigation";
import ProductCardSkeletonLoader from "@/components/SkeletonLoaders/ProductCardSkeletonLoader";
import CarouselImagePreview from "@/pages/ProductDetail/CarouselImagePreview";
import { Button } from "@/components/ui/button";
import { ChevronLeft as LeftIcon } from "lucide-react";
import Sold from "./Sold";
import SoldSuccess from "./Sold/SoldSuccess";
import Verify from "./Sold/Verify";
import {
  useGetPreSignedURLMutation,
  useGetStoreVehicleQuery,
  useGetVehicleOrderDetailsQuery,
  useUpdateOrdersMutation,
} from "@/store/services/Dmx/storeVehicles";
import { formFields, formFieldsForSellReserved } from "./data";
import EditVehicleDetailsModal from "@/components/EditVehicleDetails/EditVehicleDetailsModal";
import { containsOnlyDigits } from "@/utils/numerics";
import DmxVehicleInfo from "./DmxVehicleInfo";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import UpdateVehicleDetail from "./UpdateVehicleDetail";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { handleShare } from "@/utils/share";
import { track } from "@/utils/mixpanel/actions";
import {
  DX_CUSTOMER_DETAILS_SUBMITTED,
  DX_DOCUMENT_DOWNLOADED,
  DX_DOCUMENT_SHARED,
  DX_PDP_VIEW,
} from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import Navbar from "@/components/Navbar";
import { isValidEmail } from "@/utils/validation";
import { fallbackImage } from "@/utils/fallbackImage";

const DmxStoreDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCarouselImgPreview, setOpenCarouselImgPreview] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [storeOrderDetails, setStoreOrderDetails] = useState(undefined);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});
  const [otp, setOtp] = useState("");
  const [openQRModal, setOpenQRModal] = useState(false);
  const [openEditVehicleModal, setOpenEditVehicleModal] = useState(false);
  const [imageFound, setImageFound] = useState(true);
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useScreenDetector();
  const params = useParams();
  const sold = searchParams.get("sold") || "";
  const vehicleId = params.vehicleId;
  const type = searchParams.get("type");
  const {
    refetch: refetchGetStoreVehicle,
    data: product = {},
    isError,
  } = useGetStoreVehicleQuery({
    vehicleId,
  });
  const {
    refetch: refetchGetVehicleOrderDetail,
    data: order = {},
    isLoading: isOrderLoading,
  } = useGetVehicleOrderDetailsQuery({
    vehicle_id: vehicleId,
  });
  const [getPreSignedUrl] = useGetPreSignedURLMutation();
  const [updateOrder, { isLoading: isOrderUpdating }] =
    useUpdateOrdersMutation();
  const productDetail = product.data;
  const isLoading = !productDetail && !isError;
  const isVehicleAvailable =
    productDetail?.availabilityStatus !== "unavailable";
  const store = useSelector((state: any) => state.store.store);
  const user = useSelector((state: any) => state.user.currentUser);
  const orderDetails = order?.data?.order;
  const role = localStorage.getItem("role");

  const isOrderInReviewOrCompleted =
    (productDetail?.status === "reserved" &&
      orderDetails?.order_status === "IN_REVIEW") ||
    (productDetail?.status === "sold" &&
      orderDetails?.order_status === "COMPLETED");

  const totalAmount = Object.values(formData)
    .filter((item) => typeof item === "object" && item.amount)
    .reduce((acc, item) => acc + parseFloat(item.amount), 0);

  const finalSellingPrice =
    (Number(formData?.selling_price?.replace(/,/g, "")) || 0) -
    (parseInt(formData?.discount ?? 0) +
      (orderDetails?.payments_info[0]?.amount ?? 0) || 0);

  const remainingAmount = finalSellingPrice - totalAmount;

  const disableNextButtonForSellType =
    sold === "detail" && type === "sell" && remainingAmount !== 0;

  const selling_price = parseInt(
    productDetail?.price?.displayValue?.replace(/,/g, ""),
  );
  const minBookingAmount = 1500;
  const maxBookingAmount = selling_price - 1;

  const commonEventProperties = {
    user_id: user?.id,
    role: role,
    vehicle_name: productDetail?.vehicleName,
    vehicle_id: productDetail?.vehicleId,
    purchase_price: productDetail?.purchase_price?.displayValue,
    sale_price: productDetail?.price?.displayValue,
    km_driven: productDetail?.kmDriven?.displayValue,
    registration_number: productDetail?.registrationNumber,
    ownership: productDetail?.ownership,
    store_name: productDetail?.storeName,
    manufacturing_year: productDetail?.manufactureYear,
    customer_name: formData?.customer_name,
    customer_phone_number: formData?.customer_phone_number,
    customer_email_id: formData?.customer_email,
  };

  const isVehicleAssured = productDetail?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  useEffect(() => {
    if (productDetail) {
      track(DX_PDP_VIEW, {
        role: role,
        user_id: user?.id,
        vehicle_name: productDetail?.vehicleName,
        vehicle_id: productDetail?.vehicleId,
        purchase_price: productDetail?.purchase_price?.displayValue,
        sale_price: productDetail?.price?.displayValue,
        km_driven: productDetail?.kmDriven?.displayValue,
        registration_number: productDetail?.registrationNumber,
        ownership: productDetail?.ownership,
        manufacturing_year: productDetail?.manufactureYear,
        inventory_type: isVehicleAssured,
        store_name: productDetail?.storeName,
      });
    }
  }, [productDetail]);

  useEffect(() => {
    setFormData({
      ...formData,
      selling_price: productDetail?.price?.displayValue,
    });

    if (productDetail?.imagePaths?.length === 0) {
      setImageFound(false);
    }
  }, [productDetail?.price, productDetail?.imagePaths]);

  useEffect(() => {
    const fetchUpdatedOrder = async () => {
      const payload: any = {
        user_id: parseInt(user?.id),
        vehicle_id: parseInt(vehicleId || ""),
        store_id: parseInt(store?.store_id),
        sales_price:
          parseInt(formData.selling_price.replace(/,/g, "")) ??
          orderDetails?.price?.value,
        customer_name: formData.customer_name ?? orderDetails?.customer_name,
        customer_email_id:
          formData.customer_email ?? orderDetails?.customer_email_id,
        customer_address: formData.customer_address ?? orderDetails?.address,
        discount_amount: Number(formData?.discount) || 0,
        payments: getFormDataPaymentEntries(),
        customer_phone_number:
          formData.customer_phone_number ?? orderDetails?.customer_phone_number,
        is_booking_order: type === "reserve" ? true : false,
      };

      if (
        containsOnlyDigits(
          formData.customer_pan_or_aadhaar ||
            orderDetails?.customer_aadhaar ||
            orderDetails?.customer_pan,
        )
      ) {
        payload.customer_aadhaar =
          formData.customer_pan_or_aadhaar ?? orderDetails?.customer_aadhaar;
      } else {
        payload.customer_pan =
          formData.customer_pan_or_aadhaar ?? orderDetails?.customer_pan;
      }

      const data: any = await updateOrder({
        store_id: store?.store_id,
        data: payload,
      });

      if (data) {
        setStoreOrderDetails(data?.data?.data?.order);
      }
      setFormData({ selling_price: productDetail?.price?.displayValue });
    };

    if (
      sold === "success" &&
      Object.keys(formData).some((key) => key.startsWith("payment "))
    ) {
      fetchUpdatedOrder();
    }
  }, [sold]);

  const getFormDataPaymentEntries = () => {
    const paymentEntries = Object.keys(formData)
      .filter((key) => key.startsWith("payment "))
      .map((key) => {
        const pof_file_index =
          formData[key]?.proof_of_payment?.name?.split("_");
        const lastFileNamePart = pof_file_index?.[pof_file_index?.length - 1];

        return {
          proof_of_payment:
            formData[key]?.proof_of_payment &&
            `${productDetail?.registrationNumber}_${lastFileNamePart}.${formData[key]?.proof_of_payment?.type?.split("/")?.[1]}`,
          mode_of_payment: formData[key]?.mode_of_payment?.toUpperCase(),
          payment_reference_id: formData[key]?.payment_reference_id,
          amount:
            type === "reserve"
              ? parseInt(formData?.booking_amount)
              : parseInt(formData[key]?.amount),
          payment_type: type === "reserve" ? "ADVANCE" : "REGULAR",
        };
      });

    return paymentEntries;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex =
        (currentImageIndex + 1) % productDetail?.imagePaths?.length;

      setCurrentImageIndex(nextIndex);
    }, CAROUSEL_IMAGE_UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, productDetail?.imagePaths?.length]);

  const invokeS3Url = async (s3Url: string, method: string, data?: any) => {
    try {
      const response = await fetch(s3Url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "binary/octet-stream",
        },
      });

      return response;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
    }
  };

  const handleInputChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLInputElement
    >,
    parentField: string,
  ) => {
    const { name, value } = e.target;
    const isNumberPresent = /^\d*$/.test(value);
    const files = (e.target as HTMLInputElement)?.files;

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    // Manage Error handling for all Payment fields
    if (parentField && parentField.startsWith("payment ")) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [parentField]: {
          ...prevErrors[parentField],
          [name]: false,
        },
      }));
    }

    if (name === "discount") {
      if (parseInt(value) > DISCOUNT_MAX_VALUE + 1) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          discount: `Discount cannot be greater than ${DISCOUNT_MAX_VALUE}.`,
        }));
      }

      if (!isNumberPresent || value.length > 6) return;
    }

    if (
      name === "customer_phone_number" &&
      (!isNumberPresent || value.length > 10)
    )
      return;

    if (parentField) {
      const modifiedFile =
        files &&
        new File(
          [files[0]],
          `${files?.[0].name}_${(parentField as string).split(" ")?.[1]}`,
          { type: files?.[0].type },
        );

      setFormData((prevData: any) => ({
        ...prevData,
        [parentField]: {
          ...formData[parentField],
          [name]: modifiedFile || value,
        },
      }));

      if (files) {
        const getFileNameIndex = modifiedFile?.name.split("_");
        const lastFileNamePart =
          getFileNameIndex?.[getFileNameIndex.length - 1];
        const data: any = await getPreSignedUrl({
          data: {
            file_names: [
              `${productDetail?.registrationNumber}_${lastFileNamePart}.${files[0].type.split("/")[1]}`,
            ],
            doc_type: "proof_of_payment",
            meta_data: "",
          },
        });
        if (data) {
          const s3Url = data?.data?.data[0].url;
          if (s3Url) {
            await invokeS3Url(s3Url, "PUT", files[0]);
          }
        }
      }
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }
  };

  const getDiscount = () => {
    if (formData.discount && productDetail) {
      return (
        (parseInt(formData.discount) * 100) /
        productDetail.price.value
      ).toFixed(2);
    }
  };

  const displaySoldState = () => {
    switch (sold) {
      case "detail":
        return (
          <Sold
            getDiscount={getDiscount}
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            partnerType={store?.partner_type}
            orderDetails={orderDetails}
            setFormData={setFormData}
          />
        );
      case "verify":
        return (
          <Verify
            formData={formData}
            otp={otp}
            setOtp={setOtp}
            modelName={productDetail?.modelName}
            commonEventProperties={commonEventProperties}
          />
        );
      case "success":
        return (
          <SoldSuccess
            orderDetails={storeOrderDetails ?? orderDetails}
            handleDownload={handleDownload}
            productDetail={productDetail}
            isLoading={isOrderLoading || isOrderUpdating}
          />
        );
      default:
        return null;
    }
  };

  const validateFields = () => {
    const newErrors: any = {};
    const fields =
      orderDetails?.order_status === "RESERVED"
        ? formFieldsForSellReserved
        : formFields;

    // Error handling for all Payment fields
    Object.keys(formData).forEach((key) => {
      if (key.startsWith("payment ")) {
        const paymentField = formData[key];
        if (!paymentField) {
          newErrors[key] = {
            mode_of_payment: true,
            proof_of_payment: true,
            payment_reference_id: true,
          };
        } else {
          const modeOfPaymentError = !paymentField?.mode_of_payment;
          const proofOfPaymentError = !paymentField?.proof_of_payment;
          const paymentReferenceId = !paymentField?.payment_reference_id;

          newErrors[key] = {
            mode_of_payment: modeOfPaymentError,
            proof_of_payment: proofOfPaymentError,
            payment_reference_id: paymentReferenceId,
          };

          if (!modeOfPaymentError) {
            delete newErrors[key]?.mode_of_payment;
          }

          if (!proofOfPaymentError) {
            delete newErrors[key]?.proof_of_payment;
          }

          if (!paymentReferenceId) {
            delete newErrors[key]?.payment_reference_id;
          }

          if (Object.keys(newErrors[key]).length === 0) {
            delete newErrors[key];
          }
        }
      }
    });

    // error handling for other fields
    fields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        newErrors[field.name] = true;
      }

      // Add email validation for customer_email
      if (field.name === "customer_email" && formData[field.name]) {
        if (!isValidEmail(formData[field.name])) {
          newErrors[field.name] = true;
        }
      }

      // Booking amount validation
      if (
        field.name === "booking_amount" &&
        Number(formData.booking_amount) > maxBookingAmount
      ) {
        newErrors[field.name] =
          `Booking amount should not be greater than ${maxBookingAmount.toLocaleString("en-IN")}.`;
      }

      if (
        field.name === "booking_amount" &&
        Number(formData.booking_amount) < minBookingAmount
      ) {
        newErrors[field.name] =
          `Booking amount should not be less than ${minBookingAmount.toLocaleString("en-IN")}.`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = () => {
    if (validateFields()) {
      if (orderDetails?.order_status === "RESERVED") {
        setSearchParams(
          {
            sold: "success",
            type: type === "reserve" ? "reserve" : "sell",
          },
          {
            preventScrollReset: true,
            replace: true,
          },
        );
      } else {
        setSearchParams(
          {
            sold: "verify",
            redirect: `?sold=success&type=${type === "reserve" ? "reserve" : "sell"}`,
          },
          {
            preventScrollReset: true,
            replace: true,
          },
        );
      }
    }

    track(DX_CUSTOMER_DETAILS_SUBMITTED, commonEventProperties);
  };

  const handleDownload = async (
    type?: string,
    share = false,
    shareMessage?: string,
  ) => {
    const events = {
      user_id: user?.id,
      role: role,
      vehicle_name: productDetail?.vehicleName,
      vehicle_id: productDetail?.vehicleId,
      store_name: productDetail?.storeName,
      type: type,
    };
    const fileName = `${type}_${orderDetails.registrationNumber}.pdf`;
    const data: any = await getPreSignedUrl({
      data: {
        file_names: [`${fileName}`],
        doc_type: type,
        prefix: "",
        operation: "get",
      },
    });

    if (data) {
      const s3Url = data?.data?.data[0].url;

      if (s3Url) {
        const file = await invokeS3Url(s3Url, "GET");

        if (share) {
          track(DX_DOCUMENT_SHARED, events);
          handleShare({
            text: shareMessage,
            url: s3Url,
          });
          return;
        }

        track(DX_DOCUMENT_DOWNLOADED, events);
        downloadToDevice(file, fileName);
      }
    }
  };

  const downloadToDevice = async (file: any, fileName: string) => {
    const blob = await file?.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {!isMobile && (
        <Navbar
          backgroundColor="bg-white"
          showLocation={false}
          showStores={true}
          showDmxLogo={true}
          showRecentSearches={false}
          showPopularSearches={false}
          showPopularBrands={false}
          showLinks={false}
          disableStore={true}
          showSearchbar={false}
        />
      )}
      <div
        className={cn(
          "max-w-7xl w-full mx-auto mb-24",
          !isMobile && "pt-[129px]",
          isDesktop && "pt-[80px]",
        )}
      >
        <Dialog open={imageFound && openCarouselImgPreview}>
          <CarouselImagePreview
            isVehicleAvailable={isVehicleAvailable}
            productDetail={productDetail}
            setOpenCarouselImgPreview={setOpenCarouselImgPreview}
            showWishList={false}
            showShare={false}
            showTestRide={false}
          />
        </Dialog>

        {isLoading && <ProductCardSkeletonLoader />}

        {isDesktop && productDetail && (
          <div className="text-neutral-900 mx-4 my-6">
            <span className="text-primaryA1">{store?.store_name}</span> /{" "}
            {productDetail?.vehicleName}
          </div>
        )}
        {productDetail && (
          <div className="flex flex-col lg:flex-row gap-4 relative">
            <div className="flex flex-col justify-end md:flex-row-reverse md:h-[400px]">
              <span className="w-full relative md:w-[628px]">
                <TopNavigation
                  isVehicleAvailable={isVehicleAvailable}
                  productDetail={productDetail}
                  showWishList={false}
                  showShare={false}
                />

                <Image
                  transform="q-10"
                  src={
                    productDetail.imagePaths?.[currentImageIndex] ??
                    fallbackImage(productDetail?.vehicleType)
                  }
                  alt={productDetail.vehicleName}
                  className={cn(
                    "w-full h-80 md:h-[400px] md:w-[628px] md:rounded-md ease-linear object-cover",
                    !isVehicleAvailable && "grayscale",
                  )}
                  onClick={() => {
                    if (!imageFound) return;
                    setOpenImageViewer(true);
                    trackImageViewerOpened({
                      productDetail,
                      currentImageIndex,
                    });
                  }}
                />
                {imageFound && openImageViewer && (
                  <Dialog open={openImageViewer}>
                    <ImageViewer
                      productDetail={productDetail}
                      imagePaths={productDetail.imagePaths}
                      setOpenImageViewer={setOpenImageViewer}
                      activeImage={currentImageIndex}
                    />
                  </Dialog>
                )}
              </span>

              {imageFound && (
                <CarouselSection
                  isVehicleAvailable={isVehicleAvailable}
                  imagePaths={productDetail.imagePaths}
                  setOpenCarouselImgPreview={setOpenCarouselImgPreview}
                  productDetail={productDetail}
                />
              )}
            </div>

            <ProductInfo
              isVehicleAvailable={isVehicleAvailable}
              productInfo={productDetail}
              showEmi={false}
              showCallStore={false}
              showStoreName={false}
              showPrice={false}
              showDmxVehicleInfo={isDesktop && true}
              showTestRide={isDesktop && false}
              showUpdateVehicleDetail={isDesktop && !isOrderInReviewOrCompleted}
              setOpenEditVehicleModal={setOpenEditVehicleModal}
              openQRModal={openQRModal}
              setOpenQRModal={setOpenQRModal}
              handleDownload={handleDownload}
              orderDetails={orderDetails}
              refetchGetVehicleOrderDetail={refetchGetVehicleOrderDetail}
            />

            {!isDesktop && (
              <DmxVehicleInfo
                store={store}
                productDetail={productDetail}
                orderDetails={orderDetails}
                storeOrderDetails={storeOrderDetails}
                openQRModal={openQRModal}
                setOpenQRModal={setOpenQRModal}
                handleDownload={handleDownload}
              />
            )}
          </div>
        )}

        <Sheet
          open={sold !== ""}
          onOpenChange={() => {
            if (sold) {
              searchParams.delete("sold");
              setSearchParams(searchParams, {
                preventScrollReset: true,
                replace: true,
              });
            }
          }}
        >
          <SheetContent
            className={cn("max-w-full flex flex-col h-dvh p-2.5")}
            side={isMobile ? "bottom" : "right"}
          >
            <SheetHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-4 px-3 z-10 max-w-7xl mx-auto">
              <div className="flex gap-2">
                <SheetClose
                  onClick={() => {
                    if (sold === "detail") {
                      setFormData({
                        selling_price: productDetail?.price?.displayValue,
                      });
                      searchParams.delete("sold");
                      setSearchParams(searchParams, {
                        preventScrollReset: true,
                        replace: true,
                      });
                    } else if (sold === "verify") {
                      setSearchParams(
                        { sold: "detail" },
                        {
                          preventScrollReset: true,
                          replace: true,
                        },
                      );
                    } else if (sold === "success") {
                      navigate(`/dealer/vehicles/${vehicleId}`, {
                        preventScrollReset: true,
                        replace: true,
                      });
                    }
                  }}
                >
                  <LeftIcon />
                </SheetClose>
                <p>{type === "reserve" ? "Book Vehicle" : "Vehicle Sale"}</p>
              </div>
            </SheetHeader>

            {displaySoldState()}

            <div
              className={cn(
                "bg-white border-t absolute bottom-0 right-0 left-0 w-full py-2 px-3 flex justify-between gap-2 items-center",
                sold === "detail" && type === "sell" && "flex flex-col gap-2",
              )}
            >
              {sold === "success" ? (
                <Button
                  onClick={() => {
                    navigate(`/dealer/vehicles/${vehicleId}`, {
                      preventScrollReset: true,
                      replace: true,
                    });
                    refetchGetVehicleOrderDetail();
                    refetchGetStoreVehicle();
                  }}
                  variant="outline"
                  className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
                >
                  Finish
                </Button>
              ) : (
                <>
                  {sold === "detail" && type === "sell" && (
                    <div className="flex flex-col flex-center gap-1 p-2">
                      <p className="font-semibold text-base">
                        ₹{totalAmount?.toLocaleString("en-IN")}
                        /₹
                        {finalSellingPrice?.toLocaleString("en-IN")}
                      </p>
                      <p className="text-neutral300 text-xs">
                        {remainingAmount < 0 ? (
                          <div className="text-red600 border rounded-md border-red100 bg-[#FDECEB] px-2 py-1 font-medium text-sm">
                            ₹
                            {Math.abs(remainingAmount)?.toLocaleString("en-IN")}{" "}
                            extra
                          </div>
                        ) : (
                          `₹${remainingAmount?.toLocaleString("en-IN")} remaining`
                        )}
                      </p>
                    </div>
                  )}

                  <div className="flex w-full gap-2 items-center">
                    <Button
                      onClick={() => {
                        setFormData({
                          selling_price: productDetail?.price?.displayValue,
                        });
                        searchParams.delete("sold");
                        setSearchParams(searchParams, {
                          preventScrollReset: true,
                          replace: true,
                        });
                      }}
                      variant="outline"
                      className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        if (sold === "detail") {
                          handleNextClick();
                        } else if (sold === "verify") {
                          setSearchParams(
                            { sold: "success" },
                            {
                              preventScrollReset: true,
                              replace: true,
                            },
                          );
                        }
                      }}
                      variant="outline"
                      disabled={
                        disableNextButtonForSellType ||
                        (sold === "verify" && otp.length < 4)
                      }
                      className={cn(
                        "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
                        ((sold === "verify" && otp.length < 4) ||
                          disableNextButtonForSellType) &&
                          "bg-neutral50 text-neutral200",
                      )}
                    >
                      {sold === "detail" ? "Next" : "Verify"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </SheetContent>
        </Sheet>

        {productDetail && (
          <EditVehicleDetailsModal
            open={openEditVehicleModal}
            onClose={() => setOpenEditVehicleModal(false)}
            vehicleDetails={productDetail}
            refetchStoreVehicle={refetchGetStoreVehicle}
          />
        )}

        {!isDesktop && !isOrderInReviewOrCompleted && (
          <UpdateVehicleDetail
            productDetail={productDetail}
            setOpenEditVehicleModal={setOpenEditVehicleModal}
            orderStatus={orderDetails?.order_status}
            refetchGetVehicleOrderDetail={refetchGetVehicleOrderDetail}
          />
        )}
      </div>
    </>
  );
};

export default DmxStoreDetail;
