import { AlertToast, LeftArrowIcon } from "@/assets/Icons";
import { Input } from "@/components/ui/input";
import { ownershipOptions } from "./data";
import { cn } from "@/lib/utils";
import SelectYear from "./SelectYear";
import { Button } from "@/components/ui/button";
import { formatToIndianNumber } from "@/utils/numerics";

interface IProps {
  vehicleReceivedData: any;
  saveVehicleDetails: {
    kmDriven: string;
    manufactureYear: string;
    purchase_price: string;
    salePrice: string;
    profit: string;
  };
  setIsVehicleDetailsDialogOpen: (value: boolean) => void;
  handleSaveVehicleDetails: () => void;
  handleSelectValueChange: (value: string) => void;
  handleOwnerClick: (index: number) => void;
  updateVehicleDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: {
    [key: string]: boolean | string | { [key: string]: boolean | string };
  };
  selectedOwnership: number;
}

const AddVehicleDetails = ({
  vehicleReceivedData,
  saveVehicleDetails,
  setIsVehicleDetailsDialogOpen,
  handleSaveVehicleDetails,
  handleSelectValueChange,
  handleOwnerClick,
  updateVehicleDetails,
  errors,
  selectedOwnership,
}: IProps) => {
  if (!vehicleReceivedData || !saveVehicleDetails) return null;

  const invalidSalePrice = errors.salePrice === "Invalid Sale Price";

  const isCOCOStore = vehicleReceivedData?.store_type === "coco";
  const allowedOwnerships = [0, 1, 2];

  const fieldsToCheck = isCOCOStore
    ? Object.fromEntries(
        Object.entries(saveVehicleDetails).filter(
          ([key]) => key !== "profit" && key !== "purchase_price",
        ),
      )
    : saveVehicleDetails;

  const isDisabled =
    Object.values(fieldsToCheck).some((value) => value === "") ||
    !allowedOwnerships.includes(selectedOwnership) ||
    Object.values(errors).some((error) => error !== false);

  return (
    <div className="flex flex-col gap-4 w-full h-full overflow-y-auto">
      <div className="flex justify-start items-center gap-4 p-4 border-b border-neutral20">
        <span
          className="w-fit cursor-pointer"
          onClick={() => setIsVehicleDetailsDialogOpen(false)}
        >
          <LeftArrowIcon size={20} oneStopColor="#000" twoStopColor="#000" />
        </span>
        <div className="flex flex-col gap-1">
          <p className="text-base font-semibold">Add Details of the Vehicle</p>
          <p className="text-sm font-medium text-[#4C4C4C]">
            {vehicleReceivedData?.registrationNumber} •{" "}
            {vehicleReceivedData?.vehicleName}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-6 p-4">
        {/* KMs Driven */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">KMs Driven</p>
          <Input
            className={cn(
              "bg-neutral30 text-base font-semibold py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30 selection:bg-neutral30",
              errors.kmDriven && "bg-red100",
            )}
            value={formatToIndianNumber(saveVehicleDetails?.kmDriven)}
            name="kmDriven"
            maxLength={10}
            onChange={updateVehicleDetails}
          />
          {typeof errors.kmDriven === "string" && (
            <p className="text-red600 text-sm font-semibold">
              {errors.kmDriven}
            </p>
          )}
        </div>

        {/* Year of Manufacturing */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Year of Manufacturing</p>
          <SelectYear
            saveVehicleDetails={saveVehicleDetails}
            handleSelectValueChange={handleSelectValueChange}
          />
        </div>

        {/* Ownership */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Ownership</p>
          <div className="flex gap-2">
            {ownershipOptions.map((ownership, index) => (
              <div
                className={cn(
                  "border border-neutral100 rounded-md p-2 px-3 cursor-pointer",
                  index === selectedOwnership && "border-primaryA1",
                )}
                key={index}
                onClick={() => handleOwnerClick(index)}
              >
                <p
                  className={cn(
                    "text-neutral300 text-sm",
                    index === selectedOwnership && "text-primaryA1",
                  )}
                >
                  {ownership.ownership}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Purchase price */}
        {!isCOCOStore && (
          <div className="flex flex-col gap-3">
            <p className="text-sm font-semibold">Purchase price</p>
            <Input
              className="bg-neutralsurface100 text-base font-semibold py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30 placeholder:text-black"
              placeholder={saveVehicleDetails?.purchase_price}
              value={saveVehicleDetails?.purchase_price}
              name="purchasePrice"
              onChange={updateVehicleDetails}
              readOnly
            />
          </div>
        )}

        {/* Sale price */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Sale price</p>
          <div
            className={cn(
              "flex justify-between items-center pr-4 bg-neutral30 rounded-lg",
              invalidSalePrice && "border border-red100 bg-[#FDECEB]",
            )}
          >
            <Input
              className="bg-transparent text-base font-semibold py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30 w-fit"
              placeholder={saveVehicleDetails?.salePrice}
              value={formatToIndianNumber(saveVehicleDetails?.salePrice)}
              name="salePrice"
              maxLength={10}
              onChange={updateVehicleDetails}
              disabled={isCOCOStore ? true : false}
            />
            {invalidSalePrice && <AlertToast size={20} fillColor={"#D1372E"} />}
            {!invalidSalePrice &&
              !isCOCOStore &&
              saveVehicleDetails?.profit && (
                <span className="text-[#4A833D] text-[10px] font-semibold bg-green200 border border-[#5FA74E] px-1 py-0.5 rounded-sm">
                  + {saveVehicleDetails?.profit}% Profit
                </span>
              )}
          </div>
          {invalidSalePrice ? (
            <p className="text-red600 text-sm font-semibold">
              Sale price cannot be less than purchase price
            </p>
          ) : (
            <span className="flex items-center gap-2 text-xs text-[#912D7D] bg-[#F4EAF2] font-semibold p-1 w-full rounded-md">
              <AlertToast size={16} fillColor={"#912D7D"} /> Sale price required
              to list on website
            </span>
          )}
        </div>
      </div>

      <div className="flex justify-between gap-4 p-4 mt-auto border-t border-neutral100">
        <Button
          onClick={() => setIsVehicleDetailsDialogOpen(false)}
          variant="outline"
          className="border-orange-500 py-6 text-base font-semibold text-primaryA2 w-full hover:bg-primaryA2 hover:text-primaryA2"
        >
          Cancel
        </Button>

        <Button
          onClick={() => handleSaveVehicleDetails()}
          variant="outline"
          className="bg-primaryA2 border-orange-500 py-6 text-base font-semibold text-white w-full hover:bg-primaryA2 hover:text-white"
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddVehicleDetails;
