import FlatList from "@/components/FlatList";
import Navbar from "@/components/Navbar";
import ProductCard from "@/components/ProductCard";
import VehicleListSkeletonLoader from "@/components/VehicleListSkeletonLoader";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import {
  useGetStoreVehiclesQuery,
  useGetIncomingVehiclesQuery,
  useGetSoldVehiclesQuery,
} from "@/store/services/Dmx/storeVehicles";
import { pluralize } from "@/utils/pluralize";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DeliveryApproval from "./DeliveryApproval";
import useDidMount from "@/hooks/useDidMount";
import { track } from "@/utils/mixpanel/actions";
import { DX_USER_LOGIN } from "@/utils/mixpanel/DMXEvents/dx_hamburger_clicked_events";
import { DX_SOLD_VEHICLE_CLICKED } from "@/utils/mixpanel/DMXEvents/dx_sold_vehicle_clicked_events";
import { LeftArrowIcon } from "@/assets/Icons";

/**
 * StoreListing Component
 *
 * This component is responsible for displaying a list of vehicles available in the store.
 * It handles both the store listing and sold vehicles.
 *
 * @component
 */
const StoreListing = () => {
  const { isDesktop } = useScreenDetector();

  const user = useSelector((state: any) => state.user.currentUser);
  const { productListSessionState } = useSelector(
    (state: any) => state.productList,
  );
  const pathLocation = useLocation();
  const role = localStorage.getItem("role");
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q") || "";
  const [page, setPage] = useState(
    productListSessionState ? productListSessionState.page : 0,
  );
  const store = useSelector((state: any) => state.store.store);

  const isSoldVehiclesPage = pathLocation.pathname.includes("sold-vehicles");

  const [queryFunction, setQueryFunction] = useState(() =>
    isSoldVehiclesPage ? useGetSoldVehiclesQuery : useGetStoreVehiclesQuery,
  );

  useEffect(() => {
    setQueryFunction(() =>
      isSoldVehiclesPage ? useGetSoldVehiclesQuery : useGetStoreVehiclesQuery,
    );

    if (isSoldVehiclesPage) {
      track(DX_SOLD_VEHICLE_CLICKED, {
        role: role,
        user_id: user?.id,
      });
    }
  }, [pathLocation]);

  const {
    refetch: refetchVehiclesData,
    data: productResultsData,
    isFetching,
    isError,
  } = queryFunction(
    {
      store_id: store?.store_id,
    },
    { skip: !store?.store_id },
  );
  const {
    refetch: refetchIncomingVehicles,
    data: incomingVehiclesData,
    isFetching: isIncomingVehiclesFetching,
  } = useGetIncomingVehiclesQuery(
    {
      store_id: store?.store_id,
    },
    { skip: !store?.store_id },
  );

  const didMount = useDidMount();

  const vehicles = isSoldVehiclesPage
    ? productResultsData?.data?.orders
    : productResultsData?.data?.vehicles;

  const searchResultsData =
    vehicles?.filter(
      (item: any) =>
        item.vehicleName
          .toLowerCase()
          .startsWith(decodeURIComponent(query.toLowerCase())) ||
        item.registrationNumber
          .toLowerCase()
          .includes(decodeURIComponent(query.toLowerCase())),
    ) || [];
  const productResults = query !== "" ? searchResultsData : vehicles;

  const isListEmpty = productResults?.length <= 0 || isError;
  const hasMore =
    productResultsData?.data?.page < productResultsData?.data?.totalPages;
  const queryId = productResultsData?.data?.queryId;

  useEffect(() => {
    if (store) {
      track(DX_USER_LOGIN, {
        role: role,
        user_id: user?.id,
      });
    }
  }, [store]);

  useEffect(() => {
    if (didMount) return;
    refetchVehiclesData();
  }, [store?.store_id]);

  const handleProductCardClick = (data: IVehicleInfo) => {
    navigate(`/dealer/vehicles/${data.vehicleId}`, {
      state: { queryId, objectId: data?.objectID },
    });
  };

  const getLabel = (item: any) => {
    if (item?.status === "reserved") {
      return "Reserved";
    }
  };

  const EmptyListMessage = ({ message }: { message: React.ReactNode }) => (
    <div className="w-full h-full flex justify-center items-center text-center text-neutral200">
      {message}
    </div>
  );

  return (
    <>
      <Navbar
        backgroundColor="bg-white"
        showLocation={false}
        showStores={true}
        showDmxLogo={true}
        showRecentSearches={false}
        showPopularSearches={false}
        showPopularBrands={false}
        showLinks={false}
      />

      <div
        className={cn(
          "pt-[129px] bg-neutral30",
          isDesktop && "flex p-4 lg:pt-24",
          isListEmpty && "h-dvh",
        )}
      >
        <div
          className={cn(
            "p-4 lg:pr-0 lg:py-0 flex flex-col w-full overflow-hidden",
            isListEmpty && "h-full",
            productResults?.length <= 0 &&
              !isFetching &&
              `w-full ${isDesktop && "flex flex-col items-center"}`,
          )}
        >
          {incomingVehiclesData && !isSoldVehiclesPage && (
            <div className="bg-neutral30 max-w-7xl w-full mx-auto">
              <DeliveryApproval
                incomingVehicleData={incomingVehiclesData?.data}
                isIncomingVehiclesFetching={isIncomingVehiclesFetching}
                refetchIncomingVehicles={refetchIncomingVehicles}
                refetchVehiclesData={refetchVehiclesData}
              />
            </div>
          )}

          <div
            className={cn(
              "flex justify-between items-center max-w-7xl mx-auto w-full",
              !isDesktop && "unset",
            )}
          >
            {productResults?.length > 0 && (
              <h1 className="font-semibold text-lg flex items-center">
                {query !== "" && (
                  <span
                    className="mr-1.5 cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    <LeftArrowIcon size={24} />
                  </span>
                )}
                <span className="text-primaryA2">
                  {productResults?.length}{" "}
                  {query !== ""
                    ? decodeURIComponent(query)
                    : pluralize(
                        productResults?.length,
                        isSoldVehiclesPage ? "vehicle" : "two wheeler",
                      )}
                </span>
                &nbsp; {isSoldVehiclesPage ? "sold" : "in your store"}
              </h1>
            )}
          </div>

          <FlatList
            data={productResults}
            renderItem={({ item, index, containerRef }) => (
              <>
                <ProductCard
                  key={index}
                  product={item}
                  handleClick={() => handleProductCardClick(item)}
                  index={index}
                  containerRef={containerRef}
                  showEmi={false}
                  showWishList={false}
                  showStoreName={false}
                  vehicleAttributesClassName="text-sm"
                  removeYearFromVehicleAttributes={true}
                  label={getLabel(item)}
                />
              </>
            )}
            keyExtractor={(item: any) => item?.vehicleId?.toString()}
            loadingMore={loadingMore}
            setLoadingMore={setLoadingMore}
            hasMore={hasMore}
            isFetching={isFetching}
            setPage={setPage}
            onEndReachedThreshold={0.5}
            ListEmptyComponent={
              <EmptyListMessage
                message={
                  isSoldVehiclesPage ? (
                    "Sold vehicles will show up here!"
                  ) : (
                    <div>
                      You have no vehicles in your store. <br />
                      Order vehicles to add to the floor stock
                    </div>
                  )
                }
              />
            }
            isListEmpty={isListEmpty}
            isLoading={isFetching && page === 0}
            ListLoadingComponent={<VehicleListSkeletonLoader />}
            noMoreDataMessage="Looks like it is time to restock!"
          />
        </div>
      </div>
    </>
  );
};

export default StoreListing;
