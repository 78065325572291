import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { type CarouselApi } from "@/components/ui/carousel";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import React, { useEffect } from "react";
import StoryViewer from "./StoryViewer";
import StoryAvatars from "./StoryAvatars";
import { useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { track } from "@/utils/mixpanel/actions";
import { HOME_STORY_OPENED } from "@/utils/mixpanel/Events/home_clicked_events";
import { STORY_DURATION } from "@/utils/constants";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";

interface IProps {
  data: IStories;
}

function Stories({ data }: IProps) {
  const user = useSelector((state: any) => state.user.currentUser);
  const [startIndexOfCarousel, setStartIndexOfCarousel] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { isMobile } = useScreenDetector();
  const { city } = useCurrentCity();
  const story = parseInt(searchParams.get("story") || "");
  const [api, setApi] = React.useState<CarouselApi>();
  const indexOfCurrentCarousel = data?.data?.findIndex(
    (obj: { attributes: { profile: { id: number } } }) =>
      obj.attributes.profile.id === story,
  );
  const [current, setCurrent] = React.useState(indexOfCurrentCarousel + 1 || 0);
  const [count, setCount] = React.useState(0);
  const currentStory = data?.data?.[current - 1];
  const currentStories = currentStory?.attributes?.stories;
  const indexOfStory =
    currentStories?.findIndex((obj: { uid: string }) => obj.uid === id) || 0;
  const track_properties: IStoryEvents & {
    name: string;
    phone_number: string;
  } = {
    story_id: story,
    sub_story_id: id,
    media_type: currentStories?.[indexOfStory]?.type,
    linked_url: currentStories?.[indexOfStory]?.cta_url ?? "none",
    duration: currentStories?.length * STORY_DURATION,
    story_title: currentStory?.attributes?.profile?.name,
    city: city,
    name: user?.userName,
    phone_number: user?.phoneNumber,
  };

  useEffect(() => {
    setCurrent(indexOfCurrentCarousel + 1);
  }, [indexOfCurrentCarousel]);

  useEffect(() => {
    if (indexOfStory >= 0 && current > 0) {
      let pdpStoryEvents;
      if (currentStories?.[indexOfStory]?.type === "PDP") {
        const storyItem = currentStories?.[indexOfStory]?.vehicleData;

        pdpStoryEvents = {
          linked_url: `/${city}/buy-two-wheelers/${storyItem?.vehicleId}`,
          vehicle_name: storyItem?.vehicleName,
          store_name: storyItem?.storeName,
          vehicle_id: storyItem?.vehicleId,
        };

        track(HOME_STORY_OPENED, {
          ...track_properties,
          ...pdpStoryEvents,
        });

        return;
      }

      track(HOME_STORY_OPENED, track_properties);
    }
  }, [id, current, indexOfStory]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(indexOfCurrentCarousel + 1);
    setStartIndexOfCarousel(indexOfCurrentCarousel);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  if (!data || data?.data?.length <= 0) return;

  return (
    <div
      className={cn(
        "flex flex-col gap-5 max-w-screen-lg mx-auto w-full",
        !isMobile && "items-center",
      )}
    >
      {!isMobile && (
        <p className="text-xl md:text-[28px] md:text-center">
          What&apos;s New in <span className="text-primaryA2">DriveX</span>
        </p>
      )}

      <Dialog
        open={story ? true : false}
        onOpenChange={() => {
          if (!story) return;
          setCurrent(0);
          setSearchParams("", { preventScrollReset: true });
        }}
      >
        <DialogTrigger className="h-full p-0" asChild>
          <Carousel
            className={cn(
              "overflow-hidden px-0 md:px-0 md:pt-0 mb-[-1px] py-[10px]",
              !isMobile && "py-0",
            )}
          >
            <CarouselContent className="flex gap-3 px-3">
              {data?.data?.map(
                (item: any, index: React.Key | null | undefined) => (
                  <CarouselItem key={index} className="basis-auto pl-2">
                    <StoryAvatars
                      index={index}
                      avatar={item}
                      setStartIndexOfCarousel={setStartIndexOfCarousel}
                    />
                  </CarouselItem>
                ),
              )}
            </CarouselContent>
          </Carousel>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md w-screen h-full flex flex-col flex-center p-0 border-0">
          <Carousel
            opts={{
              align: "center",
              startIndex: startIndexOfCarousel,
            }}
            setApi={setApi}
            className="h-full w-full border-0"
          >
            <CarouselContent className="h-full bg-black">
              {data?.data?.map((_, index: number) => (
                <CarouselItem className="h-dvh w-screen" key={index}>
                  <Card className="h-full w-[100%] bg-black border-0">
                    <CardContent className="h-full flex aspect-square items-center justify-center p-0 w-[100%] border-0">
                      <StoryViewer
                        carouselApi={api}
                        current={current}
                        setCurrent={setCurrent}
                        count={count}
                        index={index}
                        indexOfCurrentCarousel={indexOfCurrentCarousel}
                        currentStory={currentStory}
                        indexOfStory={indexOfStory}
                        currentStories={currentStories}
                        track_properties={track_properties}
                      />
                    </CardContent>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Stories;
