import mixpanel from "mixpanel-browser";
import clevertap from "@/hooks/useClevertap";

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
const mixpanelDmxToken = import.meta.env.VITE_MIXPANEL_DMX_TOKEN;
const productionHost = import.meta.env.VITE_URL;

// This initializes mixpanel for the entire project.
export const mixpanelInit = () => {
  const role = localStorage.getItem("role");
  const isDealerPage = window.location.pathname.includes("dealer");

  if (role !== null && isDealerPage) {
    mixpanel.init(mixpanelDmxToken, {
      debug: false,
      // eslint-disable-next-line
      // @ts-ignore
      track_pageview: "full-url",
      persistence: "localStorage",
    });
    return;
  }
  //If the hostname is anything other than your production domain, initialize the Mixpanel library with your Development Token
  if (window.location.hostname.toLowerCase().search(productionHost) < 0) {
    mixpanel.init(mixpanelToken, {
      debug: true,
      // eslint-disable-next-line
      // @ts-ignore
      track_pageview: "full-url",
      persistence: "localStorage",
    });
  } else {
    mixpanel.init(mixpanelToken, {
      debug: false,
      // eslint-disable-next-line
      // @ts-ignore
      track_pageview: "full-url",
      persistence: "localStorage",
    });
  }
};

// This should be called on event clicks. properties will include pre attached properties from register
// as well as additional properties we send
export const track = (eventName: string, properties: object | any) => {
  // ucomment this line after authentication has been implmented & send isAuthenticated as argument
  // if(isAuthenticated) mixpanel.identify("200");
  const userId = localStorage.getItem("encodedUserId") as string;

  mixpanel.track(eventName, properties);

  clevertap()?.event.push(eventName, properties);

  // This is to exclude phone_number from updatedProperties (for google analytics)
  // eslint-disable-next-line
  const { phone_number, ...updatedProperties } = properties;

  // eslint-disable-next-line
  // @ts-ignore
  window.gtag("event", eventName, { ...updatedProperties, user_id: userId });
};

// This is used to Identify a user with a unique ID to track user activity across devices, tie a user to their events etc.
// should be used once user is authenticated to generate a distinct userID for that user in mixpanel
export const identify = ({
  user_id,
  email,
  phone_number,
  name,
}: {
  user_id: string;
  name?: string;
  email?: string;
  phone_number?: string;
}) => {
  mixpanel.identify(user_id);
  mixpanel.people.set({ $name: name, $email: email, phone_number });
};

/**
 * Allows us to send pre attached properites to every event
 */
export const register = async ({
  user_id,
  name,
  phone_number,
  is_user_logged_in,
}: {
  user_id: string;
  name?: string;
  phone_number?: string;
  is_user_logged_in: boolean;
}) => {
  mixpanel.register({
    user_id,
    is_user_logged_in,
    ...(name && { name }),
    ...(phone_number && { phone_number }),
  });
};

// Other Mixpanel operations...
