import CustomerDetail from "@/components/CustomerDetail";
import VehicleSoldSuccessSkeleton from "@/components/SkeletonLoaders/VehicleSoldSuccess";
import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";
import { useSearchParams } from "react-router-dom";

const SoldSuccess = ({
  orderDetails,
  handleDownload,
  isLoading,
  productDetail,
}: any) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const advancePayment = orderDetails?.payments_info.find(
    (payment: any) => payment.payment_mode === "ADVANCE",
  );

  if (isLoading) return <VehicleSoldSuccessSkeleton />;

  return (
    <div
      className={cn(
        "flex flex-col gap-4 mt-[70px] px-3",
        !orderDetails && "flex-center h-full mb-[70px]",
      )}
    >
      <div className="flex flex-col items-center gap-1 py-2">
        <p>
          {orderDetails?.order_status === "RESERVED"
            ? "Vehicle Booked for"
            : "Sold for"}
        </p>
        <p className="text-primaryA2 font-semibold text-4xl">
          ₹
          {orderDetails?.order_status === "RESERVED"
            ? advancePayment?.amount?.toLocaleString("en-IN")
            : orderDetails?.selling_price?.value.toLocaleString("en-IN")}
          .<span className="text-base">00</span>
        </p>
      </div>

      <div className="flex gap-4 items-center border-y py-4">
        <>
          <Image
            className="w-[92px] h-[64px] rounded-lg object-cover"
            src={orderDetails?.imagePaths?.[0]}
            alt="Vehicle"
          />

          <div className="flex flex-col gap-1">
            <p className="text-sm">{orderDetails?.vehicleName}</p>
            <p className="text-xs text-neutral300">
              {orderDetails?.registrationNumber} •{" "}
              {orderDetails?.kmDriven?.displayValue} KMs •{" "}
              {orderDetails?.manufactureYear}
            </p>
            <p className="text-sm text-neutral300">
              {type === "reserve"
                ? `Booked on ${orderDetails?.payments_info[0]?.payment_date}`
                : `Sold on ${orderDetails?.order_created_at}`}{" "}
            </p>
          </div>
        </>
      </div>

      <CustomerDetail
        orderDetails={orderDetails}
        productDetail={productDetail}
        handleDownload={handleDownload}
      />
    </div>
  );
};

export default SoldSuccess;
