import { UploadIcon } from "@/assets/Icons";

export const formFields = [
  {
    name: "selling_price",
    label: "Selling Price",
    type: "text",
    placeholder: "Enter Selling Price",
    required: true,
    description: "Final Sale Price(Includes GST)",
    disabled: "coco",
  },
  {
    name: "booking_amount",
    label: "Booking Amount",
    type: "number",
    placeholder: "Enter Booking Amount",
    required: false,
  },
  {
    name: "discount",
    label: "Discount",
    type: "text",
    placeholder: "eg. 8000",
    child: (content?: string | number | void) => {
      if (content) {
        return (
          <div className=" bg-green-100 rounded absolute top-[2.6rem] right-2 p-1 h-6 border text-green-700 border-green-600 text-xs ">
            {content}% Discount
          </div>
        );
      }
    },
  },
  {
    label: "Proof Of Payment 1",
    name: "payment 1",
    type: "custom",
    required: true,
    fields: [
      {
        name: "amount",
        type: "text",
        placeholder: "Amount",
        required: true,
      },
      {
        name: "mode_of_payment",
        type: "select",
        placeholder: "Select Mode Of Payment",
        required: true,
        options: [
          { value: "cash_on_delivery", label: "Cash" },
          { value: "upi", label: "UPI" },
          { value: "finance", label: "Finance" },
          { value: "credit_card", label: "Credit Card" },
          { value: "bank_draft", label: "Bank Draft" },
          { value: "cheque", label: "Cheque" },
          { value: "wire_transfer", label: "Wire Transfer" },
        ],
      },
      {
        name: "payment_reference_id",
        label: "Payment Reference ID",
        type: "text",
        placeholder: "Enter Payment Reference ID",
        required: false,
        maxLength: 20,
      },
      {
        name: "proof_of_payment",
        type: "file",
        placeholder: (
          <div className="flex flex-col gap-1 flex-center">
            <UploadIcon />
            <p className="text-sm">Choose a file</p>
            <p className="text-xs text-[#A9ACB4]">
              PNG, JPEG and PDF formats, up to 5MB
            </p>
          </div>
        ) as unknown as string,
        required: true,
        hide: {
          field: "mode_of_payment",
          value: "cash_on_delivery",
        },
      },
    ],
  },
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
    placeholder: "Enter Customer Name",
    required: true,
    maxLength: 30,
  },
  {
    name: "customer_email",
    label: "Customer Email",
    type: "text",
    placeholder: "Enter Customer Email",
    required: true,
  },
  {
    name: "customer_pan_or_aadhaar",
    label: "Customer Aadhaar/PAN",
    type: "text",
    placeholder: "Enter Customer Aadhaar or PAN Number",
    required: true,
    maxLength: 12,
  },
  {
    name: "customer_address",
    label: "Customer Address",
    type: "textarea",
    placeholder: "Enter Customer Address",
    required: true,
    maxLength: 500,
  },
  {
    name: "customer_phone_number",
    label: "Customer Phone Number",
    type: "text",
    placeholder: "Enter Customer Phone Number",
    required: true,
    description: "An OTP will be sent to this number",
    prefill: "+91",
    maxLength: 10,
  },
];

export const formFieldsForSellReserved = [
  {
    name: "discount",
    label: "Discount",
    type: "text",
    placeholder: "eg. 8000",
    child: (content?: string | number | void) => {
      if (content) {
        return (
          <div className=" bg-green-100 rounded absolute top-11 right-2 p-1 h-6 border text-green-700 border-green-600 text-xs ">
            {content}% Discount
          </div>
        );
      }
    },
  },
  {
    label: "Proof Of Payment 1",
    name: "payment 1", // Ensure to give a unique name if needed
    type: "custom",
    required: true,
    fields: [
      {
        name: "mode_of_payment",
        type: "select",
        placeholder: "Select Mode Of Payment",
        required: true,
        options: [
          { value: "cash_on_delivery", label: "Cash" },
          { value: "upi", label: "UPI" },
          { value: "finance", label: "Finance" },
          { value: "credit_card", label: "Credit Card" },
          { value: "bank_draft", label: "Bank Draft" },
          { value: "cheque", label: "Cheque" },
          { value: "wire_transfer", label: "Wire Transfer" },
        ],
      },
      {
        name: "payment_reference_id",
        label: "Payment Reference ID",
        type: "text",
        placeholder: "Enter Payment Reference ID",
        required: true,
        maxLength: 20,
      },
      {
        name: "proof_of_payment",
        type: "file",
        placeholder: (
          <div className="flex flex-col gap-1 flex-center">
            <UploadIcon />
            <p className="text-sm">Choose a file</p>
            <p className="text-xs text-[#A9ACB4]">
              PNG, JPEG and PDF formats, up to 5MB
            </p>
          </div>
        ) as unknown as string,
        required: true,
      },
    ],
  },
];

export const editVehicleFormFields = [
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "number",
    placeholder: "Enter Selling Price",
    required: true,
    disabled: "coco",
  },
  {
    name: "kmDriven",
    label: "KMs Driven",
    type: "number",
    placeholder: "Enter KMs Driven",
    required: true,
  },
];

export const mode_of_payment = {
  name: "mode_of_payment",
  type: "select",
  placeholder: "Select Mode Of Payment",
  required: true,
  options: [
    { value: "cash_on_delivery", label: "Cash" },
    { value: "upi", label: "UPI" },
    { value: "finance", label: "Finance" },
    { value: "credit_card", label: "Credit Card" },
    { value: "bank_draft", label: "Bank Draft" },
    { value: "cheque", label: "Cheque" },
    { value: "wire_transfer", label: "Wire Transfer" },
  ],
};
