import { Link, useSearchParams } from "react-router-dom";
import { Calendar, MapPinIcon, TimerIcon } from "lucide-react";
import { ArrowCircleUpRight } from "@/assets/Icons";
import ProductMiniCard from "@/components/ProductMiniCard";
import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import { scheduledTransactionDetails } from "@/store/services/scheduleApi";
import { convertToDDMMYYYY, getTimeSlot } from "@/utils/date";
import { ensureHttpsProtocol } from "@/utils/storeLocation";
import FAQs from "@/components/FAQs";
import { testRideFAQsData } from "@/components/FAQs/data";
import { Button } from "@/components/ui/button";
import Confetti from "react-confetti";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import clevertap from "@/hooks/useClevertap";
import MiniProductCardSkeletonLoader from "@/components/SkeletonLoaders/MiniProductCardSkeletonLoader";
import Chips from "@/components/SkeletonLoaders/Chips";
import { removeReturningCustomerData } from "@/utils/returningCustomer";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { track } from "@/utils/mixpanel/actions";
import { useSelector } from "react-redux";

const TestRide = () => {
  const [transactionDetails, setTransactionDetails] =
    useState<ITestRideRequestedResp | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams] = useSearchParams();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const transactionId = searchParams.get("transactionId") || "";
  const storeURL = ensureHttpsProtocol(
    transactionDetails?.store_map_link || "",
  );

  useEffect(() => {
    if (transactionDetails) return;
    setIsFetching(true);

    const getTransactionDeatils = async () => {
      const data = await scheduledTransactionDetails(parseInt(transactionId));

      if (data.status === "OK") {
        setTransactionDetails(data.data);
        setIsFetching(false);

        clevertap()?.notifications.push({
          titleText: "Would you like to receive Push Notifications?",
          bodyText:
            "We promise to only send you relevant content and give you updates on your transactions",
          okButtonText: "Notify me",
          rejectButtonText: "No thanks",
          okButtonColor: "#f28046",
        });

        removeReturningCustomerData({
          vehicleDetail: {
            data: data?.data?.vehicle_info,
          },
        });
      }
    };
    getTransactionDeatils();
  }, [transactionDetails]);

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Test Ride Requested Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  return (
    <>
      <Navbar
        backgroundColor="bg-neutral30"
        showLocation={false}
        showSearchbar={false}
      />

      <div className="flex flex-col gap-4 bg-neutral30 h-screen p-4 pt-[90px]">
        <div className="flex flex-col gap-3 flex-center text-center px-4">
          <ArrowCircleUpRight />
          <p className="text-2xl">Store Test Drive Confirmed</p>
          <p className="text-neutral300 text-sm">
            Visit our showroom to enjoy your test ride and discover your dream
            two-wheeler!
          </p>
        </div>

        <div className="w-full ">
          <Confetti numberOfPieces={200} recycle={false} gravity={0.2} />
        </div>

        <div className="bg-white p-4 max-w-[60rem] mx-auto w-full rounded-sm">
          {isFetching ? (
            <div className="flex flex-col item gap-3">
              <MiniProductCardSkeletonLoader />
              {Array.from({ length: 3 }, (_, index) => (
                <Chips className="h-[13px] w-full max-w-[295px]" key={index} />
              ))}
            </div>
          ) : (
            <>
              {transactionDetails && (
                <ProductMiniCard
                  productInfo={transactionDetails.vehicle_info}
                  className="px-0 items-center border-b-[1px] pb-4"
                />
              )}

              <div className="flex flex-col pt-2 gap-2">
                <div className="flex-between">
                  <div className="flex gap-2 text-sm">
                    <MapPinIcon size="20" />
                    <p className="text-sm capitalize">
                      {transactionDetails?.vehicle_info.storeName}
                    </p>
                  </div>
                  <Link
                    className="text-primaryA2 font-semibold text-sm underline underline-offset-1 decoration-orange-500 "
                    to={(transactionDetails?.store_map_link && storeURL) || ""}
                    target={transactionDetails?.store_map_link && `_blank`}
                  >
                    Directions
                  </Link>
                </div>

                <div className="flex gap-2">
                  <Calendar size="20" />
                  <p className="text-sm">
                    {transactionDetails &&
                      convertToDDMMYYYY(transactionDetails?.date)}
                  </p>
                </div>

                {transactionDetails?.time && (
                  <div className="flex gap-2">
                    <TimerIcon size="20" />
                    <p className="text-sm font-semibold">
                      {transactionDetails?.time} (
                      {getTimeSlot(transactionDetails?.time)})
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col justify-between h-full gap-4">
          <FAQs content={testRideFAQsData} handleFAQClick={handleFAQClick} />
          <Link to={`/${city}/buy-two-wheelers`}>
            <Button
              variant="outline"
              className="border-2 bg-primaryA2 text-white py-6 font-bold sm:w-[408px] mx-auto w-full mb-4 flex-center"
            >
              <p>Explore More Bikes</p>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TestRide;
