import { Button } from "@/components/ui/button.tsx";
import { useNavigate } from "react-router-dom";
import { valuePropositionData } from "./data.tsx";
import { track } from "@/utils/mixpanel/actions.ts";
import { HOME_EXPLORE_ALL_EVENT } from "@/utils/mixpanel/Events/home_clicked_events.ts";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import AvailabilityData from "@/components/AvailabilityData/index.tsx";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselDots,
  CarouselApi,
  CarouselPrevious,
  CarouselNext,
} from "@/components/ui/carousel.tsx";
import { cn } from "@/lib/utils.ts";
import { useEffect, useState } from "react";
import { AUTO_SWIPE_INTERVAL, IS_CLIENT_SIDE } from "@/utils/constants.ts";
import { Shadow } from "@/assets/svg/Homepage/Shadow.tsx";
import ReturningCustomer from "./ReturningCustomer/index.tsx";
import Image from "@/components/ui/image.tsx";
import Video from "@/components/ui/video.tsx";
import { useSelector } from "react-redux";

interface IProps {
  data: IHeroSectionWithType;
}

const BuySellExchange = ({ data }: IProps) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.currentUser);
  const { isDesktop, isMobile, isTablet } = useScreenDetector();
  const [api, setApi] = useState<CarouselApi>();
  const autoSwipeEnabled = false;
  const returningCustomerData =
    (IS_CLIENT_SIDE &&
      JSON.parse(localStorage.getItem("returningCustomerData") as string)) ||
    [];

  const { city } = useCurrentCity();

  const heroSection: IHeroSection[] = data?.data;

  const handleExploreAll = () => {
    track(HOME_EXPLORE_ALL_EVENT, {
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate(`/${city}/buy-two-wheelers`);
  };

  useEffect(() => {
    if (!api || !autoSwipeEnabled) {
      return;
    }

    const interval = setInterval(() => {
      if (api.canScrollNext()) {
        api.scrollNext();
      } else {
        api.scrollTo(0); // Scroll back to the first slide
      }
    }, AUTO_SWIPE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [api]);

  const incompleteActionTypes = [
    "test-ride",
    "whatsapp-tour",
    "schedule-inspection",
  ];

  const hasIncompleteAction = returningCustomerData?.data?.some(
    (item: { type: string }) => incompleteActionTypes.includes(item.type),
  );

  // Check if the returning customer has any incomplete actions.
  // If so, display the returning customer section even if there's only one record.
  // Otherwise, show the section only if there are two or more records.
  const shouldShowReturningCustomer = hasIncompleteAction
    ? returningCustomerData?.data?.length > 0
    : returningCustomerData?.data?.length > 1;

  const CommonCTA = ({
    ctaTitle,
    enableMetrics = true,
  }: {
    ctaTitle?: string;
    enableMetrics?: boolean;
  }) => {
    return (
      <>
        {ctaTitle && (
          <Button
            data-test-id="home-explore-all"
            onClick={handleExploreAll}
            variant="outline"
            className="bg-primaryA2 border-none md:w-[200px] text-white mx-4 md:m-0"
          >
            <p className="font-semibold text-base">{ctaTitle}</p>
          </Button>
        )}

        {enableMetrics && (
          <div className="flex justify-around text-white text-center md:justify-start gap-4 m-4 md:m-0">
            <AvailabilityData />
          </div>
        )}
      </>
    );
  };

  if (returningCustomerData && shouldShowReturningCustomer) {
    return (
      <div
        data-test-id="returningCustomer"
        data-color="bg-neutral900"
        className="content-div p-4 md:bg-returnCustomerWeb bg-cover bg-bottom bg-no-repeat md:py-[5rem] lg:py-[7rem]"
      >
        <ReturningCustomer returningCustomerData={returningCustomerData} />
      </div>
    );
  }

  if (!heroSection) return null;

  return (
    <>
      <Carousel className="w-full relative select-none" setApi={setApi}>
        {isDesktop && (
          <>
            <CarouselPrevious className="absolute left-2 top-[50%] -translate-y-1/2 z-10 bg-transparent text-white border-none hover:bg-transparent hover:text-primaryA1" />
            <CarouselNext className="absolute right-2 top-[50%] -translate-y-1/2 z-10 bg-transparent text-white border-none hover:bg-transparent hover:text-primaryA1" />
          </>
        )}

        <CarouselContent>
          {heroSection?.map((data) => {
            const mediaUrl = isMobile
              ? data?.attributes.mobileMedia.data.attributes.url
              : data?.attributes.desktopMedia.data.attributes.url;
            const isVideo =
              data.attributes.mobileMedia.data.attributes.mime.includes(
                "video",
              );

            return (
              <CarouselItem key={data.id}>
                <div
                  className={cn(
                    "flex flex-col justify-center h-[330px] md:h-[530px] mt-2 w-full relative",
                    !isVideo && "2xl:max-w-7xl mx-auto",
                  )}
                >
                  {isVideo ? (
                    <Video
                      src={mediaUrl}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <Image
                      src={mediaUrl}
                      transform="f-webp"
                      alt=""
                      className="w-full h-full object-cover"
                      loading="eager"
                      {...({ fetchpriority: "high" } as any)}
                    />
                  )}

                  {isVideo && (
                    <div
                      className={cn(
                        "w-full h-full absolute",
                        isMobile && "opacity-70",
                      )}
                    >
                      <Shadow />
                    </div>
                  )}

                  <div
                    className={cn(
                      "flex flex-col gap-7 xl:px-0 w-full absolute left-1/2 transform -translate-x-1/2 h-full justify-center",
                      !isVideo && "max-w-7xl mx-auto px-6",
                    )}
                  >
                    <div
                      className={cn(
                        "flex flex-col gap-2 md:gap-5",
                        isVideo && "absolute top-0 p-6 md:p-10",
                      )}
                    >
                      <h1 className="text-white text-xl md:text-[40px]">
                        {data?.attributes?.title}
                      </h1>
                      <p
                        className={cn(
                          "text-neutral300 text-sm md:text-base",
                          isVideo && "text-neutral-100",
                        )}
                      >
                        {data?.attributes?.subTitle}
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 md:gap-8">
                      {data?.attributes?.enableMetrics &&
                        valuePropositionData.map((info, index) => (
                          <div key={index} className="flex gap-4 items-center">
                            <span className="h-12 w-12 bg-accent rounded-full flex-center">
                              {info.icon}
                            </span>
                            <div className="flex flex-col text-white">
                              <p className="font-semibold text-xl md:text-2xl">
                                {info.quantity}
                              </p>
                              <p className="text-sm md:text-base text-neutral300">
                                {info.type}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>

                    {(isDesktop || isTablet) && (
                      <CommonCTA
                        ctaTitle={data?.attributes?.ctaTitle}
                        enableMetrics={data?.attributes?.enableMetrics}
                      />
                    )}
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <div className="h-8 md:h-0 flex-center">
          <CarouselDots className="md:absolute bottom-0 left-0 right-0" />
        </div>
      </Carousel>

      {isMobile && <CommonCTA ctaTitle="Buy Bikes Near You" />}
    </>
  );
};

export default BuySellExchange;
