import { Dialog, DialogContent } from "@/components/ui/dialog";
import AddVehicleDetails from "./AddVehicleDetails";
import { useEffect, useState } from "react";
import { calculatePercentageDifference } from "@/utils/calculatePercentageDifference";
import { useUpdateVehicleDetailsMutation } from "@/store/services/Dmx/storeVehicles";
import IncomingVehicleCard from "./IncomingVehicleCard";
import MiniProductCardSkeletonLoader from "@/components/SkeletonLoaders/MiniProductCardSkeletonLoader";
import { track } from "@/utils/mixpanel/actions";
import {
  INCOMING_VEHICLE_ADDED_TO_FLOOR_STOCK,
  INCOMING_VEHICLE_RECEIVED_BUTTON_CLICKED,
} from "@/utils/mixpanel/DMXEvents/dx_incoming_vehicle_events";
import { useSelector } from "react-redux";
import { useToast } from "@/components/ui/use-toast";

export interface IVehicleOrder {
  vehicleId: string | number;
  imagePaths: string[];
  storeId: string | number;
  store_type: string;
  kmDriven: { displayValue: string };
  manufactureYear: string | number;
  purchase_price: { displayValue: string | null };
  price: { displayValue: string | null };
  ownerShip: number;
  vehicleName: string;
  registrationNumber: string;
  storeName: string;
}

interface IProps {
  incomingVehicleData: {
    orders: IVehicleOrder[];
  };
  isIncomingVehiclesFetching: boolean;
  refetchIncomingVehicles: () => void;
  refetchVehiclesData: () => void;
}

const DeliveryApproval = ({
  incomingVehicleData,
  isIncomingVehiclesFetching,
  refetchIncomingVehicles,
  refetchVehiclesData,
}: IProps) => {
  const [isVehicleDetailsDialogOpen, setIsVehicleDetailsDialogOpen] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});
  const [vehicleReceivedData, setVehicleReceivedData] = useState<any>([]);
  const [saveVehicleDetails, setSaveVehicleDetails] = useState({
    kmDriven: "",
    manufactureYear: "",
    purchase_price: "",
    salePrice: "",
    profit: "",
  });
  const [selectedOwnership, setSelectedOwnership] = useState<number>(0);
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  const [submitVehicleDetails] = useUpdateVehicleDetailsMutation();
  const { toast } = useToast();
  const user = useSelector((state: any) => state.user.currentUser);

  const role = localStorage.getItem("role");
  const data = incomingVehicleData?.orders;
  const incomingVehiclesDataLoader = Array.from({ length: 4 }).map(
    (_, index) => <MiniProductCardSkeletonLoader key={index} />,
  );

  const profitCalculation = (purchasePrice: string, salePrice: string) => {
    if (
      (purchasePrice || salePrice) === "" ||
      vehicleReceivedData?.store_type === "coco"
    )
      return;

    const profit = calculatePercentageDifference(
      parseInt(purchasePrice?.replaceAll(",", "")),
      parseInt(salePrice?.replaceAll(",", "")),
    );

    if (profit === "error") {
      setErrors({ ...errors, salePrice: "Invalid Sale Price" });
      return;
    }

    setErrors({ ...errors, salePrice: false });
    return profit;
  };

  useEffect(() => {
    setCount(data?.length);
  }, [data]);

  useEffect(() => {
    setSelectedOwnership(vehicleReceivedData?.ownerShip - 1);
    setSaveVehicleDetails({
      ...saveVehicleDetails,
      kmDriven: vehicleReceivedData?.kmDriven?.displayValue || "",
      manufactureYear: vehicleReceivedData?.manufactureYear || "",
      purchase_price: vehicleReceivedData?.purchase_price?.displayValue || "",
      salePrice: vehicleReceivedData?.price?.displayValue || "",
      profit:
        profitCalculation(
          vehicleReceivedData?.purchase_price?.displayValue || "",
          vehicleReceivedData?.price?.displayValue || "",
        ) || "",
    });
  }, [vehicleReceivedData]);

  if (incomingVehicleData?.orders?.length <= 0) return null;

  const handleRecievedVehicleClick = (data: IVehicleOrder) => {
    track(INCOMING_VEHICLE_RECEIVED_BUTTON_CLICKED, {
      role: role,
      user_id: user?.id,
      vehicle_id: data?.vehicleId,
      vehicle_name: data?.vehicleName,
      purchase_price: data?.purchase_price?.displayValue,
      sales_price: data?.price?.displayValue,
      kms_driven: data?.kmDriven?.displayValue,
      registration_number: data?.registrationNumber,
      ownership: data?.ownerShip,
      year_of_manufacturing: data?.manufactureYear,
      store_id: data?.storeId,
      store_name: data?.storeName,
      store_type: data?.store_type,
    });

    setVehicleReceivedData(data);
    setIsVehicleDetailsDialogOpen(true);
  };

  const updateVehicleDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, "");
    if (errors[e.target.name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: false }));
    }

    const kmDriven =
      (e.target.name === "kmDriven" && e.target.value?.replaceAll(/,/g, "")) ||
      "";

    const lastRecordedKmDriven =
      vehicleReceivedData?.kmDriven?.value !== null
        ? vehicleReceivedData?.kmDriven?.value
        : undefined;

    if (parseInt(kmDriven) < lastRecordedKmDriven) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven: "KMs driven cannot be less than the last recorded value",
      }));
    }

    if (parseInt(kmDriven) > lastRecordedKmDriven + 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven:
          "KMs driven cannot exceed 100 kilometers more than the last recorded value.",
      }));
    }

    const purchasePrice =
      e.target.name === "purchase_price"
        ? e.target.value
        : saveVehicleDetails.purchase_price;
    const salePrice =
      e.target.name === "salePrice"
        ? e.target.value
        : saveVehicleDetails.salePrice;

    setSaveVehicleDetails({
      ...saveVehicleDetails,
      [e.target.name]: e.target.value,
      profit: profitCalculation(purchasePrice, salePrice) || "",
    });
  };

  const handleOwnerClick = (index: number) => {
    setSelectedOwnership(index);
  };

  const handleSaveVehicleDetails = async () => {
    try {
      const response = await submitVehicleDetails({
        vehicle_id: vehicleReceivedData?.vehicleId,
        data: {
          store_id: vehicleReceivedData?.storeId,
          store_type: vehicleReceivedData?.store_type,
          kms_driven: parseInt(saveVehicleDetails?.kmDriven.replace(/,/g, "")),
          year_of_manufacturing: parseInt(saveVehicleDetails?.manufactureYear),
          ownership: selectedOwnership + 1,
          purchase_price: parseInt(
            saveVehicleDetails?.purchase_price.replace(/,/g, ""),
          ),
          sales_price: parseInt(
            saveVehicleDetails?.salePrice.replace(/,/g, ""),
          ),
        },
      });

      if ("error" in response) {
        throw new Error(JSON.stringify(response.error));
      }

      track(INCOMING_VEHICLE_ADDED_TO_FLOOR_STOCK, {
        role: role,
        user_id: user?.id,
        vehicle_name: vehicleReceivedData?.vehicleName,
        vehicle_id: vehicleReceivedData?.vehicleId,
        purchase_price: saveVehicleDetails?.purchase_price,
        sales_price: saveVehicleDetails?.salePrice,
        kms_driven: saveVehicleDetails?.kmDriven,
        registration_number: vehicleReceivedData?.registrationNumber,
        ownership: selectedOwnership + 1,
        year_of_manufacturing: saveVehicleDetails?.manufactureYear,
        store_id: vehicleReceivedData?.storeId,
        store_type: vehicleReceivedData?.store_type,
        store_name: vehicleReceivedData?.storeName,
      });

      setIsVehicleDetailsDialogOpen(false);

      refetchIncomingVehicles();
      refetchVehiclesData();

      toast({
        title: `${vehicleReceivedData?.vehicleName} listed on the website `,
        variant: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error submitting vehicle details:", error);
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  const handleSelectValueChange = (value: string) => {
    setSaveVehicleDetails({
      ...saveVehicleDetails,
      manufactureYear: value,
    });
  };

  if (!data) return null;

  return (
    <>
      {isIncomingVehiclesFetching ? (
        <div className="flex p-4 my-3 gap-4">{incomingVehiclesDataLoader}</div>
      ) : (
        <>
          <div className="flex justify-between">
            <p className="text-base font-semibold">
              Did you receive these vehicles?
            </p>
            <p className="text-primaryA2">
              {current}/{count}
            </p>
          </div>

          <IncomingVehicleCard
            data={data}
            setCurrent={setCurrent}
            handleRecievedVehicleClick={handleRecievedVehicleClick}
          />
        </>
      )}

      {isVehicleDetailsDialogOpen && saveVehicleDetails && (
        <Dialog
          open={isVehicleDetailsDialogOpen}
          onOpenChange={setIsVehicleDetailsDialogOpen}
        >
          <DialogContent className="w-screen h-[100dvh] overflow-y-auto p-0">
            <AddVehicleDetails
              vehicleReceivedData={vehicleReceivedData}
              saveVehicleDetails={saveVehicleDetails}
              setIsVehicleDetailsDialogOpen={setIsVehicleDetailsDialogOpen}
              handleSaveVehicleDetails={handleSaveVehicleDetails}
              handleOwnerClick={handleOwnerClick}
              updateVehicleDetails={updateVehicleDetails}
              errors={errors}
              handleSelectValueChange={handleSelectValueChange}
              selectedOwnership={selectedOwnership}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DeliveryApproval;
