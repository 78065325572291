import React, { createContext, useContext, ReactNode } from "react";

interface ScreenContextProps {
  isMobile?: boolean;
  isDesktop?: boolean;
}

const ScreenContext = createContext<ScreenContextProps | undefined>(undefined);

export const ScreenProvider = ({
  children,
  isMobile,
  isDesktop,
}: {
  children: ReactNode;
  isMobile?: boolean;
  isDesktop?: boolean;
}) => {
  return (
    <ScreenContext.Provider value={{ isMobile, isDesktop }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreenContext = () => {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error("useScreenContext must be used within a ScreenProvider");
  }
  return context;
};
