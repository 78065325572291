import useOtpTimer from "@/hooks/useOtpTimer";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import {
  consumeCode,
  clearLoginAttemptInfo,
  resendCode,
} from "supertokens-web-js/recipe/passwordless";
import { useToast } from "@/components/ui/use-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserDetailsAndUpdateState } from "@/store/services/authApi";
import { setCurrentUser } from "@/store/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  OTP_RESEND_REQUEST,
  OTP_SENT_SUCCESSFULLY,
  OTP_VERIFICATION_FAILED,
  OTP_VERIFIED_SUCCESSFULLY,
} from "@/utils/mixpanel/Events/auth_clicked_events";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { track } from "@/utils/mixpanel/actions";
import { OTP_INPUT_SLOTS } from "@/utils/constants";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { usePendingAction } from "@/hooks/usePendingAction";

interface Iprops {
  userDetails: {
    phoneNumber: string;
    name: string;
    id?: string;
  };
  errorMsg: string;
  otp: string;
  isOnlyOTPVerification?: boolean;
  modelName?: string;
  eventProperties?: any;
  eventName?: string;
  searchParams: {
    delete: (action: string) => void;
    get: (action: string) => any;
  };
  setIsOtpView: React.Dispatch<React.SetStateAction<boolean>>;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  setUserDetails: React.Dispatch<
    React.SetStateAction<{ phoneNumber: string; name: string }>
  >;
  handleEdit: () => void;
}

const Otp = ({
  userDetails,
  errorMsg,
  otp,
  isOnlyOTPVerification,
  setIsOtpView,
  setOtp,
  setErrorMsg,
  searchParams,
  setUserDetails,
  modelName,
  eventProperties,
  eventName,
  handleEdit,
}: Iprops) => {
  const [restartTimer, setRestartTimer] = useState<boolean>(false);
  const { formattedTime, timerEnd } = useOtpTimer(1, 0, { restartTimer });
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop } = useScreenDetector();
  const { handlePendingAction } = usePendingAction();
  const pendingAction = useSelector(
    (state: any) => state.pendingActions.pendingAction,
  );
  const role = localStorage.getItem("role");
  const redirectTo = searchParams.get("redirect");
  const source = location?.state?.source ?? "direct";
  const isDealerPage = window.location.pathname.includes("dealer");

  async function handleOTPInput(value: string) {
    if (errorMsg.length > 0) setErrorMsg("");
    setOtp(value);

    if (value.length < 4) {
      return;
    }

    try {
      const response = await consumeCode({
        userInputCode: value,
        userContext: {
          userName: userDetails.name,
          isOnlyOTPVerification: isOnlyOTPVerification ? true : false, // To ensure backend sends an empty session and doesnt replace the accessToken in cookies
          modelName: modelName ? modelName : undefined,
        },
      });

      if (response.status === "OK") {
        await clearLoginAttemptInfo();
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          toast({
            title: isDealerPage
              ? "Successfully validated the phone number"
              : "Sign up successful",
            variant: "success",
          });
        } else {
          toast({
            title: isDealerPage
              ? "Successfully validated the phone number"
              : "Sign in successful",
            variant: "success",
          });
        }

        if (eventName) {
          track(eventName, { ...eventProperties, user_id: userDetails?.id });
        } else {
          track(OTP_VERIFIED_SUCCESSFULLY, {
            phone_number: userDetails.phoneNumber,
            status: "Success",
            source,
          });
        }

        fetchUserDetailsAndUpdateState(dispatch, setCurrentUser);
        localStorage.setItem("isUserLoggedIn", JSON.stringify(true));
        if (redirectTo) {
          navigate(`${location.pathname}/${redirectTo}`, {
            replace: true,
          });
        } else if (location.search) {
          searchParams.delete("login");
          navigate(`${location.pathname}?${searchParams}`, {
            replace: true,
          });
        } else {
          if (role && role !== "website_user") {
            navigate("/dealer/vehicles", { replace: true });
          } else {
            navigate(location.pathname, { replace: true });
          }
        }

        // Handle any pending actions
        if (pendingAction) {
          const { handleAction, payload } = pendingAction;
          handleAction(...payload);
          handlePendingAction({ actionType: "CLEAR_PENDING_ACTION" });
        }

        setUserDetails({ phoneNumber: "", name: "" });
        setOtp("");
        setIsOtpView(false);
        return;
      } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        setErrorMsg("Invalid OTP");
      } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        setErrorMsg("Invalid OTP");
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking
        await clearLoginAttemptInfo();
        setErrorMsg("Invalid OTP");
      }
      track(OTP_VERIFICATION_FAILED, {
        phone_number: userDetails.phoneNumber,
        status: "Failure",
      });
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        toast({
          title: err.message,
        });
      } else {
        toast({
          title: "Oops! Something went wrong.",
        });
      }
      track(OTP_VERIFICATION_FAILED, {
        phone_number: userDetails.phoneNumber,
        status: "Failure",
      });
    }
  }

  async function resendOTP() {
    track(OTP_RESEND_REQUEST, {
      phone_number: userDetails.phoneNumber,
    });

    if (!timerEnd) {
      return;
    }
    setRestartTimer((prevRestartTimer) => !prevRestartTimer);
    try {
      const response = await resendCode();

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        await clearLoginAttemptInfo();
        toast({
          title: "Login failed. Please try again",
          variant: "destructive",
        });
        navigate(`${location.pathname}?login=true`);
      } else {
        // OTP resent successfully.
        toast({
          title: "OTP sent successfully",
          variant: "success",
        });
        track(OTP_SENT_SUCCESSFULLY, {
          phone_number: userDetails.phoneNumber,
        });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        toast({
          title: err.message,
          variant: "destructive",
        });
      } else {
        toast({
          title: "Oops! Something went wrong.",
          variant: "destructive",
        });
      }
    }
  }

  const handlePaste: React.ClipboardEventHandler = (event) => {
    const data = event.clipboardData.getData("text");
    handleOTPInput(`${data}`);
  };

  return (
    <div className="flex flex-col gap-6 text-start">
      <div className="flex gap-2 w-full">
        <p className="text-center text-neutral300 text-base">
          Enter OTP sent to
          <span className="text-neutral900">
            &nbsp;+91{userDetails.phoneNumber}
          </span>
        </p>
        <p onClick={handleEdit} className="text-orange-500">
          Edit
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <label htmlFor="otp">Enter OTP</label>
        <InputOTP
          value={otp}
          onChange={handleOTPInput}
          inputMode="numeric"
          maxLength={OTP_INPUT_SLOTS}
          autoFocus={isDesktop}
          onPaste={handlePaste}
        >
          {Array.from({ length: OTP_INPUT_SLOTS }, (_, index) => (
            <React.Fragment key={index}>
              <InputOTPGroup>
                <InputOTPSlot
                  className={cn(
                    "bg-neutral30 flex gap-2 !w-20 !h-12 rounded-sm",
                    errorMsg && "bg-red100",
                  )}
                  index={index}
                />
              </InputOTPGroup>
            </React.Fragment>
          ))}
        </InputOTP>
        {errorMsg && <p className="text-sm text-red600">Invalid OTP</p>}
      </div>

      <p className="text-neutral300 text-center text-sm">
        Didn’t receive OTP?
        <span
          onClick={resendOTP}
          className="bg-clip-text text-transparent bg-gradient-to-r from-[#E63C32] to-[#F47823]"
        >
          {timerEnd ? " Resend" : " Resend in " + formattedTime}
        </span>
      </p>
    </div>
  );
};

export default Otp;
