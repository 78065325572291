import { useLocation } from "react-router-dom";
import { Heart, HeartFilled, LeftArrowIcon, ShareIcon } from "@/assets/Icons";
import { handleShare } from "@/utils/share";
import { track } from "@/utils/mixpanel/actions";
import { BACKBUTTON_CLICKED_EVENT } from "@/utils/mixpanel/Events/backButton_clicked_events";
import { PDP_SHARE_VEHICLE_CLICKED_EVENT } from "@/utils/mixpanel/Events/pdp_clicked_events";
import useNavigateWithFallback from "@/hooks/useNavigateWithFallback";
import { useSelector } from "react-redux";

type TopNavigationTypes = {
  isVehicleAvailable: boolean;
  productDetail: IVehicleInfo;
  handleWishListClick?: () => void;
  wishList?: number[];
  showWishList?: boolean;
  showShare?: boolean;
};

const TopNavigation = ({
  isVehicleAvailable,
  productDetail,
  handleWishListClick,
  wishList,
  showWishList = true,
  showShare = true,
}: TopNavigationTypes) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const location = useLocation();
  const navigateWithFallback = useNavigateWithFallback();

  const handleBackClick = () => {
    track(BACKBUTTON_CLICKED_EVENT, {
      url: location.pathname,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigateWithFallback();
  };

  return (
    <div className="absolute flex justify-between z-10 w-full p-4">
      <div
        className="bg-white p-2 rounded-md"
        onClick={() => handleBackClick()}
      >
        <LeftArrowIcon size={28} />
      </div>

      <div className="flex gap-4">
        {showShare && (
          <div
            className="bg-white p-2.5 rounded-md"
            onClick={() => {
              handleShare({ vehicleName: productDetail.vehicleName });
              track(PDP_SHARE_VEHICLE_CLICKED_EVENT, {
                vehicle_brand: productDetail.brand,
                vehicle_model: productDetail.modelName,
                store_location: productDetail.storeMapLink,
                vehicle_id: productDetail.vehicleId,
                name: user?.userName,
                phone_number: user?.phoneNumber,
                page: "PDP",
              });
            }}
          >
            <ShareIcon size={24} />
          </div>
        )}

        {showWishList && handleWishListClick && isVehicleAvailable && (
          <div
            id="wishlist"
            className="bg-white p-2 flex justify-center items-center rounded-md"
            onClick={() => handleWishListClick()}
          >
            {productDetail &&
            wishList &&
            wishList.includes(productDetail?.vehicleId) ? (
              <HeartFilled size={25} />
            ) : (
              <Heart size={25} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
