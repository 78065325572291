import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Otp from "@/pages/Auth/Otp";
import { createCode } from "supertokens-web-js/recipe/passwordless";
import { DX_CUSTOMER_VERIFICATION_SUCCESSFUL } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";

const Verify = ({
  formData,
  otp,
  setOtp,
  modelName,
  commonEventProperties,
}: any) => {
  const [userDetails, setUserDetails] = useState({
    phoneNumber: formData.customer_phone_number,
    name: formData.customer_name,
  });
  const [, setIsOtpView] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const sold = searchParams.get("sold") || "";
  const redirect = searchParams.get("redirect");
  const redirectType = new URLSearchParams(redirect || "").get("type");

  useEffect(() => {
    if (userDetails?.phoneNumber !== "" && sold === "verify") {
      sendOTP();
      return;
    }
  }, []);

  async function sendOTP() {
    try {
      const response = await createCode({
        phoneNumber: `+91${userDetails.phoneNumber}`,
        userContext: {
          modelName: modelName ? modelName : undefined,
          isOnlyOTPVerification: true,
        },
        options: {
          preAPIHook: async (context) => {
            const payload = context.userContext;
            let requestInit = context.requestInit;

            const body = {
              ...JSON.parse(requestInit.body?.toString() || "{}"),
              ...payload,
            };

            requestInit = {
              ...requestInit,
              body: JSON.stringify(body),
            };
            return { url: context.url, requestInit };
          },
        },
      });

      if (response.status === "OK") {
        setIsOtpView(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        if (err.message === "Phone number is invalid")
          setErrorMsg("Invalid number");
      } else {
        console.log("err", err);
      }
    }
  }

  const handleEdit = () => {
    if (errorMsg.length > 0) setErrorMsg("");
    if (otp.length > 0) setOtp("");
    searchParams.delete("redirect");
    setSearchParams(
      {
        sold: "detail",
        type: redirectType === "reserve" ? "reserve" : "sell",
      },
      {
        preventScrollReset: true,
        replace: true,
      },
    );
  };

  return (
    <div className="flex flex-col gap-5 mt-[70px]">
      <Otp
        userDetails={userDetails}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        setIsOtpView={setIsOtpView}
        otp={otp}
        setOtp={setOtp}
        searchParams={searchParams}
        setUserDetails={setUserDetails}
        isOnlyOTPVerification={true}
        modelName={modelName}
        eventProperties={commonEventProperties}
        eventName={DX_CUSTOMER_VERIFICATION_SUCCESSFUL}
        handleEdit={handleEdit}
      />
    </div>
  );
};

export default Verify;
