import ServiceCard from "@/components/ServiceCard/index.tsx";
import Image from "@/components/ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { useGetServicePackagesQuery } from "@/store/services/services.ts";

const ServicePackages = () => {
  const { data } = useGetServicePackagesQuery({});
  const { isDesktop } = useScreenDetector();
  const servicePackages = data?.data;

  return (
    <>
      <p className="text-xl md:text-[28px] md:text-center lg:py-[10px]">
        {isDesktop ? (
          <>
            Professional Services for
            <span className="text-primaryA2">&nbsp;Every Two-Wheeler</span>
          </>
        ) : (
          <>
            Professional Services <br /> for
            <span className="text-primaryA2">&nbsp;Every Two-Wheeler</span>
          </>
        )}
      </p>

      <div
        className={cn(
          "flex justify-center gap-4 max-w-screen-lg mx-auto w-full lg:flex-wrap",
          !isDesktop && "flex-col",
        )}
      >
        {servicePackages?.map((service: IService) => (
          <ServiceCard
            key={service.id}
            service={service}
            rightHalf={
              <Image
                transform="q-10,fo-auto"
                className="w-[80px] h-[80px] rounded-md object-cover"
                src={service.image_url}
                alt="Service Repair"
              />
            }
            showDescription
            showPill
            className="gap-6 lg:max-w-[40%]"
          />
        ))}
      </div>
    </>
  );
};

export default ServicePackages;
