import React from "react";
import VehicleQRModal from "./VehicleQRModal";
import { QRIcon, RightArrowIcon } from "@/assets/Icons";

type VehicleQRProps = {
  openQRModal: boolean;
  productDetail: any;
  setOpenQRModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const VehicleQR = ({
  openQRModal,
  productDetail,
  setOpenQRModal,
}: VehicleQRProps) => {
  return (
    <>
      <VehicleQRModal
        open={openQRModal}
        vehicleDetails={productDetail}
        setClose={() => setOpenQRModal(false)}
      />
      <div
        className="flex justify-between items-center p-2.5 rounded-md bg-violet50 mt-2 cursor-pointer"
        onClick={() => setOpenQRModal(true)}
      >
        <span className="flex gap-2 items-center">
          <QRIcon />
          <p className="font-semibold text-neutral700 text-sm">QR Code</p>
        </span>
        <RightArrowIcon />
      </div>
    </>
  );
};

export default VehicleQR;
