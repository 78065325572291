import { hydrateRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ScreenProvider } from "./context/useScreenContext";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { DataProvider } from "./context/useDataContext";
import React from "react";
import ErrorPage from "./pages/Home/Error";
import Providers from "./store/provider";
import * as Flagsmith from "flagsmith/react";
import flagsmith from "flagsmith";

let data;
let isMobile;
let isDesktop;

declare global {
  interface Window {
    __data__: any;
    __isMobile__: boolean;
    __isDesktop__: boolean;
  }
}

if (typeof window !== "undefined") {
  data = window?.__data__;
  isMobile = window?.__isMobile__;
  isDesktop = window?.__isDesktop__;
}

hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage onFallback={true} />}>
      <Providers>
        <HelmetProvider>
          <Flagsmith.FlagsmithProvider
            options={{
              environmentID: `${import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID}`,
              api: `${import.meta.env.VITE_FLAGSMITH_API_URL}`,
            }}
            flagsmith={flagsmith}
          >
            <BrowserRouter>
              <ScreenProvider isMobile={isMobile} isDesktop={isDesktop}>
                <DataProvider initialData={data}>
                  <App />
                </DataProvider>
              </ScreenProvider>
            </BrowserRouter>
          </Flagsmith.FlagsmithProvider>
        </HelmetProvider>
      </Providers>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
