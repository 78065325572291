import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { formFields, formFieldsForSellReserved } from "../data";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { CheckCircle, DeleteIcon, EditIcon, UploadIcon } from "@/assets/Icons";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DropdownOpenDelay } from "@/utils/constants";

type Props = {
  formData: any;
  handleInputChange: any;
  errors: any;
  partnerType: "fofo" | "coco";
  getDiscount: () => string | void;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  orderDetails: any;
};

const Sold = ({
  formData,
  handleInputChange,
  errors,
  partnerType,
  orderDetails,
  getDiscount,
  setFormData,
}: Props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [dynamicFields, setDynamicFields] = useState<any>(formFields);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const getAllPaymentFields = dynamicFields.filter((f: { name: string }) =>
    f.name.startsWith("payment "),
  );
  const lastPaymentField = getAllPaymentFields[getAllPaymentFields.length - 1];

  useEffect(() => {
    const newProofOfPayment = {
      label: "Proof Of Payment 1",
      name: "payment 1", // Ensure to give a unique name if needed
      type: "custom",
      required: true,
      fields: [
        {
          name: "mode_of_payment",
          type: "select",
          placeholder: "Select Mode Of Payment",
          required: true,
          options: [
            { value: "cash_on_delivery", label: "Cash" },
            { value: "upi", label: "UPI" },
            { value: "finance", label: "Finance" },
            { value: "credit_card", label: "Credit Card" },
            { value: "bank_draft", label: "Bank Draft" },
            { value: "cheque", label: "Cheque" },
            { value: "wire_transfer", label: "Wire Transfer" },
          ],
        },
        {
          name: "payment_reference_id",
          label: "Payment Reference ID",
          type: "text",
          placeholder: "Enter Payment Reference ID",
          required: true,
          maxLength: 20,
        },
        {
          name: "proof_of_payment",
          type: "file",
          placeholder: (
            <div className="flex flex-col gap-1 flex-center">
              <UploadIcon />
              <p className="text-sm">Choose a file</p>
              <p className="text-xs text-[#A9ACB4]">
                PNG, JPEG and PDF formats, up to 5MB
              </p>
            </div>
          ) as unknown as string,
          required: true,
        },
      ],
    };

    if (type === "sell") {
      newProofOfPayment.fields.unshift({
        name: "amount",
        type: "text",
        placeholder: "Amount",
        required: true,
      });
    }

    if (orderDetails?.order_status === "RESERVED" && type === "sell") {
      setDynamicFields([formFieldsForSellReserved[0], newProofOfPayment]);
    } else {
      setDynamicFields((prevFields: any) => {
        let updatedFields = [...prevFields];
        const proofOfPaymentIndex = updatedFields.findIndex(
          (field) => field.label === "Proof Of Payment 1",
        );
        if (proofOfPaymentIndex !== -1) {
          updatedFields[proofOfPaymentIndex] = newProofOfPayment;
        }

        if (type === "sell") {
          updatedFields = updatedFields.filter(
            (field) => field.name !== "booking_amount",
          );
        }

        if (type === "reserve") {
          updatedFields = updatedFields.filter(
            (field) => field.name !== "discount",
          );

          const booking_amount = updatedFields.find(
            (field) => field.name === "booking_amount",
          );
          booking_amount.required = true;
        }

        return updatedFields;
      });
    }
  }, [orderDetails?.order_status, type]);

  const addPaymentField = (index: number) => {
    const paymentFields = dynamicFields.filter((field: { name: string }) =>
      field.name.startsWith("payment "),
    );
    const newProofOfPayment = {
      label: `Proof Of Payment ${paymentFields.length + 1}`,
      name: `payment ${paymentFields.length + 1}`,
      type: "custom",
      required: true,
      fields: [
        {
          name: "amount",
          type: "text",
          placeholder: "Amount",
          required: true,
        },
        {
          name: "mode_of_payment",
          type: "select",
          placeholder: "Select Mode Of Payment",
          required: true,
          options: [
            { value: "cash_on_delivery", label: "Cash" },
            { value: "upi", label: "UPI" },
            { value: "finance", label: "Finance" },
            { value: "credit_card", label: "Credit Card" },
            { value: "bank_draft", label: "Bank Draft" },
            { value: "cheque", label: "Cheque" },
            { value: "wire_transfer", label: "Wire Transfer" },
          ],
        },
        {
          name: "payment_reference_id",
          label: "Payment Reference ID",
          type: "text",
          placeholder: "Enter Payment Reference ID",
          required: true,
          maxLength: 20,
        },
        {
          name: "proof_of_payment",
          type: "file",
          placeholder: (
            <div className="flex flex-col gap-1 flex-center">
              <UploadIcon />
              <p className="text-sm">Choose a file</p>
              <p className="text-xs text-[#A9ACB4]">
                PNG, JPEG and PDF formats, up to 5MB
              </p>
            </div>
          ) as unknown as string,
          required: true,
        },
      ],
    };

    const updatedFields = [...dynamicFields];
    updatedFields.splice(index + 1, 0, newProofOfPayment); // Insert after the specified index
    setDynamicFields(updatedFields); // Update state
  };

  const renderCustomComponent = (field: any, index: number) => {
    if (field?.name?.includes("payment ")) {
      return renderMultiMode(field, index);
    } else {
      return null;
    }
  };

  const handleDelete = (index: number) => {
    const payment = dynamicFields[index];

    setDynamicFields((prevFields: any) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });

    setFormData((prevFormData: any) => {
      const updatedFormData = { ...prevFormData };
      delete updatedFormData[payment.name];
      return updatedFormData;
    });
  };

  const renderMultiMode = (field: any, index: number) => {
    if (type === "reserve") {
      return (
        <div className="flex flex-col gap-2">
          <Select
            open={isDropdownOpen}
            onOpenChange={(val) =>
              setTimeout(() => setIsDropdownOpen(val), DropdownOpenDelay)
            }
            onValueChange={(value) =>
              handleInputChange &&
              handleInputChange(
                {
                  target: {
                    name: field.fields[0].name,
                    value,
                  },
                },
                field?.name,
              )
            }
            value={formData[field.name]?.[field.fields[0].name]}
          >
            <SelectTrigger
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                ((!errors[field?.name]?.[field?.fields[0]?.name] &&
                  errors[field?.name] === true) ||
                  errors[field?.name]?.[field?.fields[0]?.name]) &&
                  "bg-red-100",
              )}
              name={field.name}
            >
              <SelectValue placeholder="Select a Mode" />
            </SelectTrigger>
            <SelectContent>
              {field.fields[0].options?.map((option: any) => (
                <SelectItem
                  key={option.value}
                  className="focus:bg-neutral30"
                  value={option.value}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Input
            className={cn(
              "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
              ((!errors[field?.name]?.[field?.fields[1]?.name] &&
                errors[field?.name] === true) ||
                errors[field?.name]?.[field?.fields[1]?.name]) &&
                "bg-red-100",
            )}
            name={field.fields[1].name}
            placeholder={field.fields[1].placeholder}
            type={field.fields[1].type}
            required={field.fields[1].required}
            value={formData[field.name]?.payment_reference_id || ""}
            onChange={(e) =>
              handleInputChange(
                {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                },
                field?.name,
              )
            }
            maxLength={field.fields[1].maxLength}
          />

          <>
            <div
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 cursor-pointer",
                ((!errors[field?.name]?.[field?.fields[2]?.name] &&
                  errors[field?.name] === true) ||
                  errors[field?.name]?.[field?.fields[2]?.name]) &&
                  "bg-red-100",
              )}
              onClick={() =>
                document
                  .getElementById(
                    `file-input-${field?.name}-${field.fields[2].name}`,
                  )
                  ?.click()
              }
            >
              {formData[field?.name]?.["proof_of_payment"] ? (
                <div className="flex gap-2 items-center px-2 justify-between">
                  {" "}
                  <div className="flex gap-2">
                    <CheckCircle />{" "}
                    <span className="text-sm">
                      {formData[field?.name]?.["proof_of_payment"]?.name}
                    </span>
                  </div>
                  <EditIcon size={20} fill="#D1372E" />
                </div>
              ) : (
                field.fields[2].placeholder
              )}
            </div>
            <input
              id={`file-input-${field?.name}-${field.fields[2].name}`}
              className="hidden"
              name={field.fields[2].name}
              type="file"
              accept=".png, .jpg, .jpeg, .webp, .pdf"
              onChange={(e) =>
                handleInputChange(
                  {
                    target: {
                      name: field.fields[2].name,
                      value: e.target.value,
                      files: e.target.files,
                    },
                  },
                  field?.name,
                )
              }
            />
          </>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-2">
          <div className="flex w-full gap-2">
            <Input
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                errors[field.fields[0].name] && "bg-red-100",
              )}
              name={field.fields[0].name}
              placeholder={field.fields[0].placeholder}
              type={field.fields[0].type}
              required={field.fields[0].required}
              value={formData[field.name]?.amount || ""}
              onChange={(e) =>
                handleInputChange(
                  {
                    target: {
                      name: e.target.name,
                      value: e.target.value,
                    },
                  },
                  field?.name,
                )
              }
              disabled={partnerType === field.fields[0].disabled}
              maxLength={field.fields[0].maxLength}
            />

            <Select
              onValueChange={(value) =>
                handleInputChange &&
                setTimeout(() => {
                  handleInputChange(
                    {
                      target: {
                        name: field.fields[1].name,
                        value,
                      },
                    },
                    field?.name,
                  );
                }, DropdownOpenDelay)
              }
              value={formData[field.name]?.[field.fields[1].name]}
            >
              <SelectTrigger
                className={cn(
                  "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                  errors[field?.name]?.[field?.fields[1]?.name] && "bg-red-100",
                )}
                name={field.name}
              >
                <SelectValue placeholder="Select a Mode" />
              </SelectTrigger>
              <SelectContent>
                {field.fields[1].options?.map((option: any) => (
                  <SelectItem
                    key={option.value}
                    className="focus:bg-neutral30"
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <Input
            className={cn(
              "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
              errors[field?.name]?.[field.fields[2].name] && "bg-red-100",
            )}
            name={field.fields[2].name}
            placeholder={field.fields[2].placeholder}
            type={field.fields[2].type}
            required={field.fields[2].required}
            value={formData[field.name]?.payment_reference_id || ""}
            onChange={(e) =>
              handleInputChange(
                {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                },
                field?.name,
              )
            }
            maxLength={field.fields[2].maxLength}
          />

          <>
            <div
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 cursor-pointer",
                errors[field?.name]?.[field?.fields[3]?.name] && "bg-red-100",
              )}
              onClick={() =>
                document
                  .getElementById(
                    `file-input-${field?.name}-${field?.fields[3]?.name}`,
                  )
                  ?.click()
              }
            >
              {formData[field.name]?.["proof_of_payment"] ? (
                <div className="flex gap-2 items-center px-2 justify-between">
                  {" "}
                  <div className="flex gap-2">
                    <CheckCircle />{" "}
                    <span className="text-sm">
                      {formData[field.name]?.["proof_of_payment"]?.name}
                    </span>
                  </div>
                  <EditIcon size={20} fill="#D1372E" />
                </div>
              ) : (
                field?.fields[3]?.placeholder
              )}
            </div>
            <input
              id={`file-input-${field?.name}-${field?.fields[3]?.name}`}
              className="hidden"
              name={field?.fields[3]?.name}
              type="file"
              onChange={(e) =>
                handleInputChange(
                  {
                    target: {
                      name: field?.fields[3]?.name, // Update to use the correct field name
                      value: e.target.value,
                      files: e.target.files,
                    },
                  },
                  field?.name,
                )
              }
            />
          </>

          {field.name.includes("payment ") &&
            !dynamicFields[index + 1]?.name?.includes("payment ") && (
              <div
                onClick={() => addPaymentField(index)}
                className="flex flex-center p-2.5 w-full border-2 rounded-md border-primaryA1 text-primaryA2 border-dashed text-xs"
              >
                + Add another payment
              </div>
            )}
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={cn(
          "flex flex-col gap-5 mt-[70px] overflow-auto",
          type === "sell" && "mb-[135px]",
          type === "reserve" && "mb-[70px]",
        )}
      >
        {orderDetails?.order_status === "RESERVED" && type === "sell" && (
          <div className="grid grid-cols-2 gap-4 text-left border-b py-4 px-2">
            <div className="flex flex-col gap-1">
              <p className="text-xs text-neutral300">Listing Price</p>
              <p>₹ {orderDetails?.price?.value?.toLocaleString("en-IN")}</p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-xs text-neutral300">Booking Amount</p>
              <p>
                ₹{" "}
                {orderDetails?.payments_info?.[0]?.amount?.toLocaleString(
                  "en-IN",
                )}
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-xs text-neutral300">Customer Phone Number</p>
              <p>+91 {orderDetails?.customer_phone_number}</p>
            </div>
          </div>
        )}

        {dynamicFields.map((field: any, index: number) => {
          return (
            <div key={field.name} className="flex flex-col gap-2 relative">
              {field.label && (
                <div className="flex justify-between">
                  <label
                    htmlFor={field.name}
                    className="text-sm font-semibold flex gap-0.5"
                  >
                    {field.label}
                    {field.required && <p className="text-red-600">*</p>}
                  </label>

                  {getAllPaymentFields?.length > 1 &&
                    lastPaymentField?.name === field?.name && (
                      <div onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                      </div>
                    )}
                </div>
              )}
              {field.type === "textarea" ? (
                <Textarea
                  className={cn(
                    "bg-neutral30 h-24 mb-2 focus-visible:ring-0 focus-visible:ring-offset-0",
                    errors[field.name] && "bg-red-100",
                  )}
                  name={field.name}
                  placeholder={field.placeholder}
                  maxLength={field.maxLength}
                  value={formData[field.name as keyof typeof formData] || ""}
                  onChange={handleInputChange}
                />
              ) : field.type === "custom" ? (
                renderCustomComponent(field, index)
              ) : (
                <div
                  className={cn(
                    "flex items-center px-4 rounded-md bg-neutral30 border-none",
                    errors[field.name] && "bg-red-100",
                    !field?.prefill && "none p-0",
                  )}
                >
                  {field?.prefill && (
                    <span className="text-sm">{field.prefill}</span>
                  )}
                  <Input
                    className={cn(
                      "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                      errors[field.name] && "bg-red-100",
                    )}
                    name={field.name}
                    placeholder={field.placeholder}
                    type={field.type}
                    required={field.required}
                    value={formData[field.name as keyof typeof formData] || ""}
                    onChange={handleInputChange}
                    disabled={partnerType === field.disabled}
                    maxLength={field.maxLength}
                  />
                </div>
              )}
              {typeof errors[field.name] === "string" && (
                <span className="text-xs text-red-400">
                  {errors[field.name]}
                </span>
              )}
              {field.description && (
                <p className="text-neutral300 text-sm">{field.description}</p>
              )}
              {field.name === "discount" &&
                field.child &&
                field.child(getDiscount())}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Sold;
