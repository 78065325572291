import CleverTap from "clevertap-web-sdk/clevertap";

let clevertapInstance: CleverTap;

const clevertap = () => {
  import("clevertap-web-sdk").then((clevertapModule) => {
    clevertapInstance = clevertapModule?.default;
  });

  return clevertapInstance;
};

export default clevertap;
