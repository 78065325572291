import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { X as CloseIcon } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import UserDetails from "./UserDetails";
import BuySell from "./BuySell";
import { useSelector } from "react-redux";
import UserData from "./UserData";
import { getSidebarClickedButton } from "./data";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { track } from "@/utils/mixpanel/actions";
import { ACCOUNT_OPENED } from "@/utils/mixpanel/Events/auth_clicked_events";
import MoreOptions from "./MoreOptions";
import { DriveXLogo, PlainCallIcon } from "@/assets/Icons";
import { Switch } from "../ui/switch";
import { useEffect, useState } from "react";
import useDidMount from "@/hooks/useDidMount";
import { cn } from "@/lib/utils";
import { dealerSupportContactNumber, IS_CLIENT_SIDE } from "@/utils/constants";
import { DX_ENABLE_DEALER_MODE } from "@/utils/mixpanel/DMXEvents/dx_hamburger_clicked_events";

interface IProps {
  openSidebar: boolean;
  setOpenSidebar: any;
}

const Sidebar = ({ openSidebar, setOpenSidebar }: IProps) => {
  const role = IS_CLIENT_SIDE && localStorage.getItem("role");
  const [dealerMode, setDealerMode] = useState(
    role && role !== "undefined" ? true : false,
  );
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  const location = useLocation();
  const pageSource = getPageSource(location.pathname);
  const didMount = useDidMount();

  // close sidebar on page change
  useEffect(() => {
    setOpenSidebar(false);
  }, [pageSource]);

  useEffect(() => {
    if (didMount) return;

    setTimeout(() => {
      if (dealerMode) {
        track(DX_ENABLE_DEALER_MODE, {
          user_id: user?.id,
          role: role,
        });

        localStorage.setItem("role", user?.roles[0]);
        window.location.href = "/dealer/vehicles";
      } else {
        localStorage.removeItem("role");
        window.location.href = "/";
      }
    }, 100);
  }, [dealerMode]);

  const handleOnUserDetailsClick = () => {
    if (user) {
      track(ACCOUNT_OPENED, {
        source: pageSource,
        tab: "Buy",
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });

      if (dealerMode) {
        navigate("/dealer-profile");
        return;
      }

      navigate("/profile");
      return;
    }

    navigate(`${location.pathname}?login=true`, setOpenSidebar(false));
  };

  const handleUserDataClick = (value: string) => {
    setOpenSidebar(false);

    if (!user && value !== "contactUs") {
      navigate(`${location.pathname}?login=true`, setOpenSidebar(false));
      return;
    }

    if (value === "sell") {
      navigate("/profile", { state: { text: "sell" } });
      return;
    }

    const URL = getSidebarClickedButton(value);
    navigate(`${URL}`);
  };

  const handleCheckedChange = () => {
    setDealerMode(!dealerMode);
  };

  return (
    <>
      <Sheet open={openSidebar} onOpenChange={() => setOpenSidebar(false)}>
        <SheetContent
          side={isMobile ? "left" : "right"}
          className={cn(
            "w-[80%] flex flex-col justify-between gap-4 p-2 px-3 overflow-y-auto h-dvh",
          )}
        >
          <div
            className={cn(
              "flex flex-col gap-4",
              user?.roles?.length > 1 && "h-[calc(100dvh-50px)] overflow-auto",
            )}
          >
            <div className="flex flex-col gap-4">
              <SheetHeader>
                <div className="flex justify-between border-b items-center py-4">
                  <span
                    data-test-id="drivex-logo"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <DriveXLogo size={86} />
                  </span>
                  <SheetClose>
                    <CloseIcon
                      size={20}
                      onClick={() => setOpenSidebar(false)}
                    />
                  </SheetClose>
                </div>
              </SheetHeader>

              <div className="flex gap-4 py-2">
                <UserDetails
                  user={user}
                  handleOnUserDetailsClick={handleOnUserDetailsClick}
                />
              </div>

              {!dealerMode && (
                <div className="flex gap-3 flex-wrap">
                  <UserData handleUserDataClick={handleUserDataClick} />
                </div>
              )}

              <div className="flex flex-col p-2">
                <MoreOptions dealerMode={dealerMode} />
              </div>

              {dealerMode && (
                <div
                  className="flex gap-2.5 py-4 px-4 w-full rounded-md border-t border-neutral50"
                  onClick={() => {
                    window.open(`tel:${dealerSupportContactNumber}`);
                  }}
                >
                  <PlainCallIcon />
                  <p className="font-semibold text-neutral900 text-sm">
                    Support
                  </p>
                </div>
              )}
            </div>

            {!dealerMode && (
              <div className="flex flex-col mb-1 gap-2 w-full">
                <BuySell setOpenSidebar={setOpenSidebar} navigate={navigate} />
              </div>
            )}
          </div>

          {user?.roles?.length > 1 && (
            <div className="flex justify-between sticky bottom-0 right-0 left-0 border border-neutralsurface100 p-2.5 rounded-lg items-center">
              <p>Dealer Mode</p>
              <Switch
                checked={dealerMode}
                onCheckedChange={handleCheckedChange}
                className="data-[state=checked]:bg-primaryA2"
              />
            </div>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default Sidebar;
