import { configureStore } from "@reduxjs/toolkit";
import api from "./services/api.ts";
import { blogApi } from "./services/blog.tsx";
import { getOrderedVehicle } from "./services/ordersApi.ts";
import { dealerApi } from "./services/dealer.ts";
import locationReducer from "./features/locationSlice.ts";
import userReducer from "./features/userSlice.ts";
import allBrandsReducer from "./features/allBrandsSlice.ts";
import estimatedPriceReducer from "./features/estimatedPriceSlice.ts";
import wishListReducer from "./features/wishListSlice.tsx";
import productListReducer from "./features/productListingSlice.ts";
import pendingActionsReducer from "./features/pendingActionsSlice.ts";
import { bodApi } from "./services/aboutUs.ts";
import { servicesApi } from "./services/services.ts";
import { homeApi } from "./services/home.ts";
import { dmxApi } from "./services/Dmx/storeVehicles.tsx";
import storeReducer from "./features/storeSlice.ts";
import {
  becomeADealerApi,
  submitDealerFormApi,
} from "./services/becomeADealer.ts";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [getOrderedVehicle.reducerPath]: getOrderedVehicle.reducer,
    [dealerApi.reducerPath]: dealerApi.reducer,
    [bodApi.reducerPath]: bodApi.reducer,
    [becomeADealerApi.reducerPath]: becomeADealerApi.reducer,
    [submitDealerFormApi.reducerPath]: submitDealerFormApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [dmxApi.reducerPath]: dmxApi.reducer,
    location: locationReducer,
    user: userReducer,
    allBrands: allBrandsReducer,
    estimatedPrice: estimatedPriceReducer,
    wishList: wishListReducer,
    productList: productListReducer,
    pendingActions: pendingActionsReducer,
    store: storeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      blogApi.middleware,
      getOrderedVehicle.middleware,
      dealerApi.middleware,
      bodApi.middleware,
      becomeADealerApi.middleware,
      submitDealerFormApi.middleware,
      servicesApi.middleware,
      homeApi.middleware,
      dmxApi.middleware,
    ),
});

export default store;
