import { useScreenContext } from "@/context/useScreenContext";
import { IS_CLIENT_SIDE } from "@/utils/constants";
import { useEffect, useState } from "react";

export const useScreenDetector = () => {
  const { isMobile: isMobileContext, isDesktop: isDesktopContext } =
    useScreenContext();

  const [width, setWidth] = useState(() =>
    IS_CLIENT_SIDE ? window.innerWidth : 0,
  );

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (IS_CLIENT_SIDE) {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
  }, [IS_CLIENT_SIDE]);

  const isMobile = isMobileContext || (IS_CLIENT_SIDE && width <= 768);
  const isTablet = IS_CLIENT_SIDE && width > 768 && width <= 1024;
  const isDesktop = isDesktopContext || (IS_CLIENT_SIDE && width > 1024);
  const isLargeScreen = isDesktop && width <= 1280;

  return { isMobile, isTablet, isDesktop, isLargeScreen };
};
